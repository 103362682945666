import { MyNftResult } from '@pages/MyInfo';
import React, { FC, useEffect, useState } from 'react';
import Loading from '@components/Loading';
import { FigureWrapper } from '@pages/Minting';
import { Link } from 'react-router-dom';
import { getMyInfo } from '@api/account';
import { getMtNft, getNftProjectLists, NftProjectReq } from '@api/app';
import axios from 'axios';
import dayjs from 'dayjs';
import { cursorPointerStyle } from '@utils/stlyes';

interface Props {
  isLoading: boolean;
  myNfts: MyNftResult[];
}

const Metakingz = () => {
  const [myNfts, setMyNfts] = useState<MyNftResult[]>([]);
  const [isLoading, setLoading] = useState(false);
  const [project, setProject] = useState<NftProjectReq | null>(null);

  const isCheckMintTime = () => {
    const currentTime = dayjs(new Date()).unix() * 1000;
    const startTime = dayjs(project?.start).unix() * 1000;
    const endTime = dayjs(project?.finish).unix() * 1000;

    return !(currentTime > endTime);
  };

  const bootstrap = async () => {
    setLoading(true);

    const myNftsResult = await getMtNft();
    const buffer: MyNftResult[] = [];
    const result = await getNftProjectLists();
    setProject(result);

    const nftInfo = await Promise.allSettled(
      myNftsResult.map(async (item) => {
        const url = item.tokenURI;
        const result = await axios.get(url).then((resp) => resp.data);
        return result as { name: string; description: string; image: string };
      })
    );
    nftInfo.forEach((data, i) => {
      if (data.status === 'fulfilled') {
        buffer.push({ title: myNftsResult[i].name, ...data.value });
      }
    });
    setMyNfts(buffer);
    setLoading(false);
  };

  useEffect(() => {
    bootstrap();
  }, []);

  return (
    <div className='my-metakings'>
      {isLoading ? (
        <Loading />
      ) : myNfts.length ? (
        <div className='my-list'>
          <ul>
            {myNfts.map((item, i) => (
              <li key={i}>
                <FigureWrapper>
                  <img src={item.image} />
                </FigureWrapper>
              </li>
            ))}
          </ul>
          {/*<a href="#" className="btn-type01">*/}
          {/*  More*/}
          {/*</a>*/}
        </div>
      ) : (
        <div className='empty'>
          <strong>
            <i>보유하고 있는</i> METAKINGZ가 없습니다.
          </strong>
          <p>METAKINGZ 홀더가 되어 다양한 멤버십 혜택을 즐겨보세요.</p>
          {/*  {isCheckMintTime() ? (
            <Link to="/mint" className="btn-type01">
              METAKINGZ 구매하러 가기
            </Link>
          ) : (
            <a
              href="https://opensea.io/collection/metakingz"
              target="_blank"
              style={cursorPointerStyle}
              className="btn-type01"
            >
              METAKINGZ 구매하러 가기
            </a>
          )} */}
        </div>
      )}
    </div>
  );
};

export default Metakingz;
