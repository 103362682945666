import axios from 'axios';

import { APICore } from './index';
import { AbiItem } from 'caver-js';
import { AddIndexPropsBaseQrInfo } from '@components/Modal/CouponQr';
import { EventQrProps } from '@components/WelcomeCard';
//
const api = new APICore();
//
export interface MainBannerReq {
  url: string;
  popup: boolean;
  background: string;
}

export interface NftProjectReq {
  stawrt?: number;
  desc: string;
  idx: number;
  blockNumber: number;
  finish: number;
  maxmint: number;
  homepage?: string;
  kakaotalk?: string;
  twitter?: string;
  telegram?: string;
  thumbnail: string;
  minted: number;
  price: number;
  decimal: number;
  start: number;
  maximum: number;
  title: string;
  blockchain: {
    contract: string;
  };
}

export interface AssetsNftItem {
  idx: number;
  price: number;
  maximum: number;
  minted: number;
  soldout: boolean;
  title: string;
  ticker: string;
  thumbnail: string;
  decimal: number;
  contract: string;
  abi: string;
}

export interface MyNftItem {
  name: string;
  symbol: string;
  tokenURI: string;
}

export interface IABI {
  abi: AbiItem[];
}

export interface QrHolder {
  qrCode: string;
}

export interface MyTier {
  nftCount: number;
  userGrade: number;
  userGradeName: string;
  userState: number;
  userUid: number;
  walletAddr: string;
  balance: number;
}

export interface BenefitLists {
  couponList: any;
  couponType: number;
  coupontTypeDesc: string;
  coupontTypeName: string;
}

export interface EventLists {
  eventDesc: string;
  eventEndDate: string;
  eventName: string;
  eventStartDate: string;
  eventUseYn: string;
  eventUserAvailabilityYn: string;
}

export interface BenefitHistory {
  userCouponSn: string;
  userUid: number;
  userDate: string;
}

export const getMainBanner = async (): Promise<MainBannerReq[]> => {
  return api.get('/apps/main/popup').then((res) => res.data.data.banners);
};

export const getNftProjectLists = (): Promise<NftProjectReq> => {
  return api.get('/nfts/project').then((resp) => resp.data.data.project);
};

export const getNftAssetsNftLists = (idx: number): Promise<AssetsNftItem[]> => {
  return api.get(`/nfts/project/${idx}/items`).then((resp) => resp.data.data.items);
};

export const getRpcInfo = async () => {
  return api.get('/apps/blockchain').then((resp) => resp.data.data.blockchain);
};

export const saveTxId = async (txid: string | null, tag: string) => {
  return api.create(`/nfts/project/mint`, { txid, tag }).then((resp) => resp.data.data);
};

export const getNftProject = (idx: number): Promise<NftProjectReq> => {
  return api.get(`/nfts/project/${idx}`).then((resp) => resp.data.data.project);
};

export const getMintData = async (count: number): Promise<{ tag: string; url: string; gasFee: number }> => {
  return axios.get(`/nfts/project/mint/data?count=${count}`).then((resp) => resp.data.data);
};

export const getMtNft = (): Promise<MyNftItem[]> => {
  return api.get('/nfts/me').then((resp) => resp.data.data);
};

export const getAbiData = async (method: string | ''): Promise<IABI> => {
  return api.get(`nfts/project/abi?method=${method}`).then((resp) => resp.data.data);
};

export const getWhiteLists = async () => {
  return api.get('/nfts/project/whitelist').then((resp) => resp.data.data);
};

export const getQrHolder = (): Promise<QrHolder> => {
  return api.get(`/apps/qrcode/holders`).then((resp) => resp.data.data);
};

export const getMyTier = async (): Promise<MyTier> => {
  return api.get(`/apps/membership/me`).then((resp) => resp.data.data);
};

export const getMyBenefit = async (mode: string): Promise<BenefitLists[]> => {
  return api.get(`/apps/membership/benefits/${mode}`).then((resp) => resp.data.data.benefitsList);
};

export const getMyEventLists = async (): Promise<EventLists[]> => {
  return api.get(`/apps/membership/benefits/event`).then((resp) => resp.data.data.eventList);
};

export const getEventQrCoupon = async (sn: string): Promise<EventQrProps> => {
  return api.get(`/apps/qrcode/event/${sn}`).then((resp) => resp.data.data);
};

export const getBenefitHistory = async (type: number): Promise<BenefitHistory[]> => {
  return api.get(`/apps/membership/benefits/${type}/history`).then((resp) => resp.data.data);
};

export interface BaseQrInfo {
  info: {
    couponName: string;
    couponDesc: string;
  };
  qrCode: string;
}

export const getQrCoupon = async (sn: string): Promise<BaseQrInfo> => {
  return api.get(`/apps/qrcode/coupons/use/${sn}`).then((resp) => resp.data.data);
};

export const shareQrCoupon = async (sn: string): Promise<AddIndexPropsBaseQrInfo> => {
  return api.get(`/apps/qrcode/coupons/share/${sn}`).then((resp) => resp.data.data);
};
