import img_gallery from '@assets/images/img_gallery.jpg';
import img_gallery_m from '@assets/images/img_gallery-m.jpg';
import img_slide from '@assets/images/ico_slide-arrow.png';

const NftGalleryPage = () => {
  return (
    <>
      <article>
        <section className="nft-gallery">
          <div className="gallery-img">
            <span className="pc">
              <img src={img_gallery} alt="" />
            </span>
            <span className="mobile">
              <img src={img_gallery_m} alt="" />
            </span>
          </div>
          <div className="arrow">
            <a href="#" className="prev">
              <img src={img_slide} alt="" />
            </a>
            <a href="#" className="next">
              <img src={img_slide} alt="" />
            </a>
          </div>
          <a href="#" className="btn-type01 white">
            Coming Soon
          </a>
        </section>
      </article>
    </>
  );
};

export default NftGalleryPage;
