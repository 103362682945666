import { AddIndexPropsBaseQrInfo, DivContainer } from '@components/Modal/CouponQr';
import { FC, useEffect, useState } from 'react';
import { cursorPointerStyle } from '@utils/stlyes';
import img_pop_close_btn from '@assets/images/ico_pop-close.png';
import QRCode from 'react-qr-code';
import { isMobile } from '@pages/Minting';
import img_arrow from '@assets/images/ico_pop-arrow.png';
import { shareQrCoupon } from '@api/app';
import { EventQrProps } from '@components/WelcomeCard';

interface Props {
  closeModal: () => void;
  sn: string;
  eventQr: EventQrProps | null;
  bootstrap: () => Promise<void>;
}

const EventQrModal: FC<Props> = ({ closeModal, sn, eventQr, bootstrap }) => {
  const [modalQrLists, setModalQrLists] = useState([]);
  const [currentQr, setCurrentQr] = useState<EventQrProps | null>(null);
  const [shareQr, setShareQr] = useState<AddIndexPropsBaseQrInfo | null>(null);

  useEffect(() => {
    if (eventQr) {
      setCurrentQr(eventQr);
    }
  }, [JSON.stringify(eventQr)]);

  return (
    <DivContainer className="popup-wrap" style={{ display: 'block', overflow: 'auto' }}>
      <div className="dim" />

      <div className="pop-type01 pop-qr-benefit">
        <a
          style={cursorPointerStyle}
          className="pop-close"
          onClick={async () => {
            await bootstrap();
            closeModal();
          }}
        >
          <img src={img_pop_close_btn} alt="" />
        </a>
        <strong className="pop-tit black text-l">{currentQr?.info.eventName}</strong>
        <p className="desc color-orange">현장에서 QR코드 스캔 시 혜택이 차감됩니다.</p>
        <div className="qr">
          <div className="code" style={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
            {shareQr ? (
              <QRCode value={shareQr?.qrCode!} size={isMobile() || window.innerWidth < 960 ? 160 : 200} />
            ) : (
              currentQr && (
                <QRCode value={currentQr?.qrCode!} size={isMobile() || window.innerWidth < 960 ? 160 : 200} />
              )
            )}
          </div>
          <p>{shareQr ? shareQr?.qrCode.slice(0, 20) : currentQr?.qrCode.slice(0, 20)}....</p>
          {/*<a style={cursorPointerStyle} className="btn-prev" onClick={prevClickHandler}>*/}
          {/*  <img src={img_arrow} alt="" />*/}
          {/*</a>*/}
          {/*<a style={cursorPointerStyle} className="btn-next" onClick={nextClickHandler}>*/}
          {/*  <img src={img_arrow} alt="" />*/}
          {/*</a>*/}
        </div>
        {/*<a*/}
        {/*  style={cursorPointerStyle}*/}
        {/*  onClick={async () => {*/}
        {/*    if (currentQr?.sn) {*/}
        {/*      const result = await shareQrCoupon(currentQr.sn);*/}
        {/*      setShareQr(result);*/}
        {/*    }*/}
        {/*  }}*/}
        {/*  className="btn-coupon"*/}
        {/*>*/}
        {/*  쿠폰 저장하기*/}
        {/*</a>*/}
      </div>
    </DivContainer>
  );
};

export default EventQrModal;
