import img_pop_close from '@assets/images/ico_pop-close.png';
import React, { FC, SetStateAction } from 'react';
// @ts-ignore
import { prepare, request, getResult, getCardList } from 'klip-sdk';
import klipConnectWallet from '@utils/klip/connectKlipWallet';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { accountInfoAtom, isLoginAtom, loginWalletAtom } from '@recoil/atoms';
interface Props {
  closeModal: () => void;
  kaikasConnectHadnler: () => void;
  klipConnectHandler: () => void;
  loginOpenModal: () => void;
  setLoginQr: React.Dispatch<SetStateAction<string>>;
  closeQrModal: () => void;
  setQrMessage: React.Dispatch<SetStateAction<string>>;
  walletLoginBtnRef: any;
}

const SelectWalletModal: FC<Props> = ({
  closeModal,
  kaikasConnectHadnler,
  klipConnectHandler,
  loginOpenModal,
  setLoginQr,
  closeQrModal,
  setQrMessage,
  walletLoginBtnRef,
}) => {
  const setAccountInfo = useSetRecoilState(accountInfoAtom);
  const setIsLogin = useSetRecoilState(isLoginAtom);
  const [loginWallet, setLoginWallet] = useRecoilState(loginWalletAtom);

  return (
    <div className="popup-wrap" style={{ display: 'block' }}>
      <div className="dim" />
      <div className="pop-type01 width02 pop-wallet-connect" id="connectWallet">
        <a href="#" className="pop-close" onClick={closeModal}>
          <img src={img_pop_close} alt="" />
        </a>
        <strong>지갑 연결</strong>
        <p>연결 할 지갑을 선택해주세요.</p>
        <ul>
          <li
            className="kaikas"
            onClick={() => {
              kaikasConnectHadnler();
              closeModal();
            }}
          >
            <a href="#">
              <span>Kaikas 지갑으로 연결</span>
            </a>
          </li>
          <li
            className="klip"
            ref={walletLoginBtnRef}
            onClick={() => {
              klipConnectWallet({
                openModal: loginOpenModal,
                setLoginQr,
                setAccountInfo,
                setIsLogin,
                closeModal,
                closeQrModal,
                setQrMessage,
                setLoginWallet,
              });
            }}
          >
            <a href="#">
              <span>카카오 Klip 지갑으로 연결</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default SelectWalletModal;
