import { getAbiData, getMintData, getWhiteLists, IABI, saveTxId } from '@api/app';
import Caver from 'caver-js';
import React, { SetStateAction } from 'react';
import { MyInfo } from '@api/account';
// @ts-ignore

import { prepare, request, getResult, getCardList } from 'klip-sdk';
import { bappName, failLink, pcMobileLogin, successLink } from '@utils/klip/connectKlipWallet';
import { jsonStr } from '@utils/klip/connectKlipMobileWallet';
import { isMobile } from '@pages/Minting';

interface Props {
  caver: Caver;
  contractAddress: string;
  setAlertMessage: React.Dispatch<SetStateAction<string>>;
  alertOpenModal: () => void;
  whiteListOpenModal: () => void;
  accountInfo: MyInfo;
  count: number;
  price: number | null;
  setMintQr: React.Dispatch<SetStateAction<string>>;
  openMintQrModal: () => void;
  setQrTitle: React.Dispatch<SetStateAction<string>>;
  loadingOpenModal: () => void;
  openModal: () => void;
  setCount: React.Dispatch<SetStateAction<number>>;
  successOpenModal: () => void;
  bootstrap: () => void;
  loadingCloseModal: () => void;
  closeMintQrModal: () => void;
}

export const executeContractKlipPc = async ({
  caver,
  contractAddress,
  setAlertMessage,
  alertOpenModal,
  whiteListOpenModal,
  accountInfo,
  count,
  price,
  setMintQr,
  openMintQrModal,
  setQrTitle,
  loadingOpenModal,
  openModal,
  setCount,
  successOpenModal,
  bootstrap,
  loadingCloseModal,
  closeMintQrModal,
}: Props) => {
  const abiData = await getAbiData('');
  const mintContract = caver.contract.create(abiData.abi, contractAddress);
  const isMintPossible = await mintContract.methods._paused().call();
  const mintAbi = await getAbiData('mint');

  if (isMintPossible) {
    setAlertMessage('원활한 민팅을 위해 민팅이 잠시 중지되었습니다.');
    alertOpenModal();
    return;
  }

  const whiteLists = (await getWhiteLists()) as { addresses: string[]; enabled: boolean };
  if (whiteLists.enabled) {
    // const whiteLists = await mintContract.methods.getWhiteList().call({ from: accountInfo?.address });
    const lowerWhiteLists = whiteLists.addresses.map((item: any) => item.toLowerCase());
    if (!lowerWhiteLists.includes(accountInfo.address.toLowerCase())) {
      whiteListOpenModal();
      return;
    }
  }

  const { tag, gasFee } = await getMintData(count);
  localStorage.setItem('tag', tag);
  const contractParams = `[\"${count}\"]`;
  const bappName = 'METAKINGZ';
  const from = accountInfo.address;
  const to = contractAddress;
  const value = caver.utils.toPeb(String(price), 'KLAY');
  const abi = JSON.stringify(mintAbi.abi);
  const params = contractParams;

  const res = await prepare.executeContract({ bappName, from, to, value, abi, params });

  if (res.err) {
    // 에러 처리
    setAlertMessage('에러가 발생했습니다.');
    alertOpenModal();
    return;
  } else if (res.request_key) {
    // request_key 보관
    if (isMobile()) {
      await request(res.request_key);
      let mintTimer = setInterval(async () => {
        // loadingOpenModal();
        const _result = await getResult(res.request_key);

        if (_result.status === 'requested') {
          loadingOpenModal();
          closeMintQrModal();
        }

        if (_result.status === 'canceled') {
          clearInterval(mintTimer);
          closeMintQrModal();
          openModal();
          loadingCloseModal();
          await saveTxId(null, tag);
          return;
        }

        if (res.status === 'error') {
          clearInterval(mintTimer);
          closeMintQrModal();
          await saveTxId(res.data.result.tx_hash, tag);
          loadingCloseModal();

          loadingCloseModal();
          setCount(1);
        }

        if (_result.status === 'completed') {
          try {
            if (_result.result.status === 'success') {
              clearInterval(mintTimer);
              closeMintQrModal();
              await saveTxId(_result.result.tx_hash, tag);
              await bootstrap();
              loadingCloseModal();
              setCount(1);
              successOpenModal();
            } else {
              clearInterval(mintTimer);
              closeMintQrModal();
              await saveTxId(null, tag);
              await bootstrap();
              loadingCloseModal();
              setCount(1);
              openModal();
            }
          } catch (err) {
            alert(jsonStr(err));
            openModal();
            clearInterval(mintTimer);
            await saveTxId(null, tag);
          }
        }
      }, 1000);

      return;
    }

    setMintQr(`https://klipwallet.com/?target=/a2a?request_key=${res.request_key}`);
    setQrTitle('Klip Mint QR');
    openMintQrModal();
    await request(res.request_key, () => {
      console.log('auth');
    });
    let mintTimer = setInterval(async () => {
      const _result = await getResult(res.request_key);

      if (_result.status === 'requested') {
        loadingOpenModal();
        closeMintQrModal();
      }
      if (_result.status === 'canceled') {
        clearInterval(mintTimer);
        closeMintQrModal();
        openModal();
        loadingCloseModal();
        await saveTxId(null, tag);
        return;
      }

      if (res.status === 'error') {
        clearInterval(mintTimer);
        closeMintQrModal();
        await saveTxId(res.data.result.tx_hash, tag);
        loadingCloseModal();

        loadingCloseModal();
        setCount(1);
      }

      if (_result.status === 'completed') {
        try {
          if (_result.result.status === 'success') {
            clearInterval(mintTimer);
            closeMintQrModal();
            await saveTxId(_result.result.tx_hash, tag);
            await bootstrap();
            loadingCloseModal();
            setCount(1);
            successOpenModal();
          } else {
            clearInterval(mintTimer);
            closeMintQrModal();
            await saveTxId(null, tag);
            await bootstrap();
            loadingCloseModal();
            setCount(1);
            openModal();
          }
        } catch (err) {
          alert(jsonStr(err));
          openModal();
          loadingCloseModal();
          clearInterval(mintTimer);
          await saveTxId(null, tag);
        }
      }
    }, 1000);
  }
};
