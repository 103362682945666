import { useNavigate } from 'react-router';
import Carousel from 'nuka-carousel';
import img_kv_utopia from '@assets/images/img_kv-utopia.png';
import img_kv_tiger from '@assets/images/img_kv-tiger.png';
import img_bg_world_nft from '@assets/images/bg_world-nft.png';
import img_minting_nft from '@assets/images/img_minting-nft.jpg';
import { Link } from 'react-router-dom';
import FAQLists from '@components/FAQ';
import PartnerLists from '@components/PartnerLists';
import TeamLists from '@components/TeamLists';
import Membership from '@components/Membership';
import MetaWorld from '@components/MetaWorld';
import MetaStory from '@components/MetaStory';
import RoadMap from '@components/RoadMap';
import momentTimeZone from 'moment-timezone';
import moment from 'moment';
import img_logo_big from '@assets/images/logo_big.png';
import ReactCountDown, { zeroPad } from 'react-countdown';
import { cursorPointerStyle } from '@utils/stlyes';
import { getNftProjectLists } from '@api/app';
import { useEffect, useLayoutEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { accountInfoAtom, finishAtom } from '@recoil/atoms';
import { easeLinear, easeElasticOut } from 'd3-ease';
import styled from '@emotion/styled';
import useModal from '@hooks/useModal';
import InfoModal from '@components/Modal/Info';

const UlWrapper = styled.ul`
  @media (max-width: 1280px) {
    .slider-list {
      width: 400% !important;
    }
  }
`;

const DDAY = 1671062400000;
const HomePage = () => {
  const navigate = useNavigate();

  const [finish, setFinish] = useState<number | null>(null);
  const [countDown, setCountDown] = useState<number | null>(null);
  const [accountInfo] = useRecoilState(accountInfoAtom);

  const { ModalPortal, openModal, closeModal } = useModal();
  const [infoMessage, setInfoMessage] = useState('');
  useEffect(() => {}, []);

  const calcData = () => {
    const krTime = momentTimeZone(finish)
      .tz('Asia/Seoul')
      .format('YYYY-MM-DD HH:mm:ss');
    const localTime = moment(finish).format('YYYY-MM-DD HH:mm:ss');
    const differ = moment(krTime).valueOf() - moment(localTime).valueOf();

    return moment(localTime).valueOf() - differ;
  };

  // useEffect(() => {
  //   navigate('/mint');
  // }, []);
  const renderer = ({
    days,
    hours,
    minutes,
    seconds,
    completed,
  }: {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
    completed: boolean;
  }) => {
    if (completed) {
      return (
        <>
          <ul>
            <li>
              <span>
                <i>0</i>
                <i>0</i>
              </span>
              <em>Days</em>
            </li>
            <li>
              <span>
                <i>0</i>
                <i>0</i>
              </span>
              <em>Hours</em>
            </li>
            <li>
              <span>
                <i>0</i>
                <i>0</i>
              </span>
              <em>Minutes</em>
            </li>
            <li>
              <span>
                <i>0</i>
                <i>0</i>
              </span>
              <em>Seconds</em>
            </li>
          </ul>
        </>
      );
    } else {
      // Render a countdown
      return (
        <>
          <ul>
            <li>
              <span>
                <i>{zeroPad(String(days))[0]}</i>
                <i>{zeroPad(String(days))[1]}</i>
              </span>
              <em>Days</em>
            </li>
            <li>
              <span>
                <i>{zeroPad(String(hours))[0]}</i>
                <i>{zeroPad(String(hours))[1]}</i>
              </span>
              <em>Hours</em>
            </li>
            <li>
              <span>
                <i>{zeroPad(String(minutes))[0]}</i>
                <i>{zeroPad(String(minutes))[1]}</i>
              </span>
              <em>Minutes</em>
            </li>
            <li>
              <span>
                <i>{zeroPad(String(seconds))[0]}</i>
                <i>{zeroPad(String(seconds))[1]}</i>
              </span>
              <em>Seconds</em>
            </li>
          </ul>
        </>
      );
    }
  };
  const getFinishTime = async () => {
    const data = await getNftProjectLists();
    const krTime = momentTimeZone(data.finish)
      .tz('Asia/Seoul')
      .format('YYYY-MM-DD HH:mm:ss');
    const localTime = moment(data.finish).format('YYYY-MM-DD HH:mm:ss');
    const differ = moment(krTime).valueOf() - moment(localTime).valueOf();

    setCountDown(moment(localTime).valueOf() - differ);
    setFinish(data.finish);
  };

  useLayoutEffect(() => {
    getFinishTime();
  }, []);

  return (
    <>
      <article>
        <section className='landing'>
          <div className='kv'>
            <strong className='utopia'>
              <img src={img_kv_utopia} alt='' />
            </strong>
            <figure className='tiger'>
              <img src={img_kv_tiger} alt='' />
            </figure>
            {/* <a
              style={cursorPointerStyle}
              onClick={() => {
                navigate('/mint');
              }}
              className='btn-mintin'
            >
              <span>
                멤버십 구매<i className='br'>(minting)</i>
              </span>
            </a> */}

            {/*<div className="count">*/}
            {/*  {countDown ? (*/}
            {/*    <ReactCountDown date={countDown} renderer={renderer}>*/}
            {/*      <></>*/}
            {/*    </ReactCountDown>*/}
            {/*  ) : null}*/}

            {/*  <Link to="/mint" className="btn-mintin">*/}
            {/*    <span>minting</span>*/}
            {/*  </Link>*/}
            {/*</div>*/}
          </div>

          {/* <MetaWorld /> */}

          {/* <MetaStory /> */}

          {/* <div className='meta-nft'>
            <div className='inner'>
              <h2 className='tit'>Metakingz NFT</h2>
              <div className='bg'>
                <img src={img_bg_world_nft} alt='' />
              </div>
              <div className='nft-list' style={{ overflow: 'auto' }}>
                <UlWrapper>
                  <Carousel
                    withoutControls
                    wrapAround={true}
                    autoplay={true}
                    autoplayInterval={1500}
                    speed={2000}
                    slidesToShow={4}
                    cellAlign='center'
                    cellSpacing={40}
                    pauseOnHover={false}
                    easing={(t) => {
                      return t;
                    }}
                  >
                    <li>
                      <img src={img_minting_nft} alt='' />
                    </li>
                    <li>
                      <img src={img_minting_nft} alt='' />
                    </li>
                    <li>
                      <img src={img_minting_nft} alt='' />
                    </li>
                    <li>
                      <img src={img_minting_nft} alt='' />
                    </li>
                    <li>
                      <img src={img_minting_nft} alt='' />
                    </li>
                    <li>
                      <img src={img_minting_nft} alt='' />
                    </li>
                    <li>
                      <img src={img_minting_nft} alt='' />
                    </li>
                    <li>
                      <img src={img_minting_nft} alt='' />
                    </li>
                    <li>
                      <img src={img_minting_nft} alt='' />
                    </li>
                    <li>
                      <img src={img_minting_nft} alt='' />
                    </li>
                    <li>
                      <img src={img_minting_nft} alt='' />
                    </li>
                  </Carousel>
                </UlWrapper>
              </div>
              <a
                style={cursorPointerStyle}
                href='https://opensea.io/collection/metakingz'
                target='_blank'
                className='btn-view'
                rel='noreferrer'
              >
                View on OpenSea +
              </a>{' '}
            </div>
          </div> */}
          {/* <RoadMap /> */}

          <Membership />

          {/* <TeamLists /> */}
          {/* <PartnerLists /> */}
          {/* <FAQLists /> */}
        </section>
        <ModalPortal>
          <InfoModal text={infoMessage} closeHandler={closeModal} />
        </ModalPortal>
      </article>
    </>
  );
};

export default HomePage;
