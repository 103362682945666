import { FC, useEffect, useState } from 'react';
import img_pop_close_btn from '@assets/images/ico_pop-close.png';
import { BaseQrInfo, getEventQrCoupon, shareQrCoupon } from '@api/app';
import QRCode from 'react-qr-code';

import img_arrow from '@assets/images/ico_pop-arrow.png';
import { cursorPointerStyle } from '@utils/stlyes';
import styled from '@emotion/styled';
import { isMobile } from '@pages/Minting';

export interface AddIndexPropsBaseQrInfo extends BaseQrInfo {
  id: number;
  sn: string;
}

interface Props {
  closeModal: () => void;
  sn: string;
  mode: 'offline' | 'online' | 'event';
  qrLists: AddIndexPropsBaseQrInfo[];
  bootstrap: () => Promise<void>;
}

export const DivContainer = styled.div`
  .code {
    display: flex;
    justify-content: center;
    align-content: center;
    top: 20px !important;
    padding: 0 !important;
  }

  @media (max-width: 1280px) {
    p {
      font-size: 14px !important;
    }
    .code {
      margin-bottom: 30px !important;
    }
  }
`;

const CouponQrModal: FC<Props> = ({ closeModal, sn, mode, qrLists, bootstrap }) => {
  const [modalQrLists, setModalQrLists] = useState([]);
  const [currentQr, setCurrentQr] = useState<AddIndexPropsBaseQrInfo | null>(null);
  const [shareQr, setShareQr] = useState<AddIndexPropsBaseQrInfo | null>(null);

  const prevClickHandler = () => {
    if (currentQr?.id === 1) {
      return;
    }

    if (currentQr?.id) {
      const descIndex = currentQr?.id - 1;
      const findData = qrLists.find((qrItem) => {
        return qrItem.id === descIndex;
      });

      if (findData) {
        setCurrentQr(findData);
      }
    }
    setShareQr(null);
  };

  const isVisiblePrevArrow = () => {
    if (qrLists.length === 1) {
      return false;
    }

    return currentQr?.id !== qrLists.slice(0)[0]?.id;
  };

  const isVisibleNextArrow = () => {
    if (qrLists.length === 1) {
      return false;
    }
    return currentQr?.id !== qrLists.slice(-1)[0]?.id;
  };

  const nextClickHandler = () => {
    if (qrLists.length === currentQr?.id) {
      return;
    }
    if (currentQr?.id) {
      const addIndex = currentQr?.id + 1;
      const findData = qrLists.find((qrItem) => {
        return qrItem.id === addIndex;
      });

      if (findData) {
        setCurrentQr(findData);
      }
    }
    setShareQr(null);
  };

  useEffect(() => {
    if (qrLists.length) {
      setCurrentQr(qrLists[0]);
    }
  }, [JSON.stringify(qrLists)]);

  return (
    <DivContainer className="popup-wrap" style={{ display: 'block', overflow: 'auto' }}>
      <div className="dim" />

      <div className="pop-type01 pop-qr-benefit">
        <a
          style={cursorPointerStyle}
          className="pop-close"
          onClick={async () => {
            await bootstrap();
            closeModal();
          }}
        >
          <img src={img_pop_close_btn} alt="" />
        </a>
        <strong className="pop-tit black text-l">{currentQr?.info.couponName}</strong>
        <p className="desc color-orange">현장에서 QR코드 스캔 시 혜택이 차감됩니다.</p>
        <div className="qr">
          <div className="code" style={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
            {shareQr ? (
              <QRCode value={shareQr?.qrCode!} size={isMobile() || window.innerWidth < 960 ? 160 : 200} />
            ) : (
              currentQr && (
                <QRCode value={currentQr?.qrCode!} size={isMobile() || window.innerWidth < 960 ? 160 : 200} />
              )
            )}
          </div>
          <p>{shareQr ? shareQr?.qrCode.slice(0, 20) : currentQr?.qrCode.slice(0, 20)}....</p>
          {isVisiblePrevArrow() && (
            <a style={cursorPointerStyle} className="btn-prev" onClick={prevClickHandler}>
              <img src={img_arrow} alt="" />
            </a>
          )}
          {isVisibleNextArrow() && (
            <a style={cursorPointerStyle} className="btn-next" onClick={nextClickHandler}>
              <img src={img_arrow} alt="" />
            </a>
          )}
        </div>
        {/*<a*/}
        {/*  style={cursorPointerStyle}*/}
        {/*  onClick={async () => {*/}
        {/*    if (currentQr?.sn) {*/}
        {/*      const result = await shareQrCoupon(currentQr.sn);*/}
        {/*      setShareQr(result);*/}
        {/*    }*/}
        {/*  }}*/}
        {/*  className="btn-coupon"*/}
        {/*>*/}
        {/*  쿠폰 저장하기*/}
        {/*</a>*/}
      </div>
    </DivContainer>
  );
};

export default CouponQrModal;
