import img_minting_nft from '@assets/images/img_minting-nft.jpg';
import img_btn_remove from '@assets/images/btn_remove.png';
import img_btn_add from '@assets/images/btn_add.png';
import styled from '@emotion/styled';
import {
  getAbiData,
  getMintData,
  getNftProjectLists,
  getWhiteLists,
  NftProjectReq,
  saveTxId,
} from '@api/app';
import { useEffect, useState } from 'react';
import Caver from 'caver-js';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  accountInfoAtom,
  isLoginAtom,
  isMintLoadingAtom,
  loginWalletAtom,
} from '@recoil/atoms';
import { APICore } from '@api/index';
import useModal from '@hooks/useModal';
import MintFailModal from '@components/Modal/Fail';
import SuccessMintModal from '@components/Modal/Sucess';
import axios from 'axios';
import { KLAYTN_MOBILE_PREPARE_URL } from '@pages/MyInfo';
import InfoModal from '@components/Modal/Info';

import dayjs from 'dayjs';
import WhiteListsFailModal from '@components/Modal/WhiteListsFail';
import LoadingInfoModal from '@components/Modal/LoadingMinting';
import { executeContractKlipPc } from '@utils/klip/excuteContrractKlipPc';
import ExecuteMintQr from '@components/Modal/ExecuteMintQr';
import LoginQrModal from '@components/Modal/LoginQr';
import {
  jsonStr,
  KLIP,
  walletLoginKey,
} from '@utils/klip/connectKlipMobileWallet';

export const SpanWrapper = styled.span<{ width: string }>`
  width: ${(props) => props.width} !important;
`;

const PWrapper = styled.p`
  font-family: SUIT;
  font-size: 12px;
  line-height: 1.71;
  color: #8a8aa0;
  a {
    font-weight: 500;
    color: #f58768;
    text-decoration: underline;
  }
`;

const localTime = new Date();

export const timezone = Math.abs(localTime.getTimezoneOffset() / 60);

export const FigureWrapper = styled.figure`
  max-width: 720px;
`;
export function isMobile() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );
}
// window.klaytn ? window.klaytn : 'https://public-node-api.klaytnapi.com/v1/cypress'
// process.env.REACT_APP_BUILD_ENV === 'development'
//   ? 'https://api.baobab.klaytn.net:8651'
//   : 'https://public-node-api.klaytnapi.com/v1/cypress'
const api = new APICore();
// window.klaytn ? window.klaytn : 'https://api.baobab.klaytn.net:8651'
const MintingPage = () => {
  const [project, setProject] = useState<NftProjectReq | null>(null);
  const [count, setCount] = useState(1);
  const [currentBlockNumber, setCurrentBlockNumber] = useState<number | null>(
    null
  );
  const [price, setPrice] = useState<number | null>(null);
  const caver = new Caver(
    window.klaytn
      ? window.klaytn
      : new Caver.providers.HttpProvider(
          'https://public-node-api.klaytnapi.com/v1/cypress'
        )
  );
  const [isLogin, setIsLogin] = useRecoilState(isLoginAtom);
  const [accountInfo, setAccountInfo] = useRecoilState(accountInfoAtom);
  const [maxMint, setMaxMint] = useState<number | null>(null);
  const { ModalPortal, openModal, closeModal } = useModal();
  const {
    ModalPortal: SuccessModal,
    openModal: successOpenModal,
    closeModal: successCloseModal,
  } = useModal();
  const {
    ModalPortal: AlertModal,
    openModal: alertOpenModal,
    closeModal: alertCloseModal,
  } = useModal();
  const {
    ModalPortal: WhiteListModalPortal,
    openModal: whiteListOpenModal,
    closeModal: whiteListsCloseModal,
  } = useModal();

  const {
    ModalPortal: MintQrPortal,
    openModal: openMintQrModal,
    closeModal: closeMintQrModal,
  } = useModal();

  const {
    ModalPortal: LoadingModalPortal,
    openModal: loadingOpenModal,
    closeModal: loadingCloseModal,
  } = useModal();
  const [mintQr, setMintQr] = useState('');
  const [qrTitle, setQrTitle] = useState('');
  const [alertMessage, setAlertMessage] = useState('');

  const [mintLoading, setMintLoading] = useRecoilState(isMintLoadingAtom);
  const loginWallet = useRecoilValue(loginWalletAtom);

  const onSignOut = () => {
    localStorage.removeItem('isLogin');
    localStorage.removeItem(walletLoginKey);
    api.setLoggedInUser(null);

    window.location.reload();
  };

  const executeContract = async ({ to, tag }: { to: string; tag: string }) => {
    const mintAbi = await getAbiData('mint');
    const params = `[\"${count}\"]`;
    const chainId =
      process.env.REACT_APP_BUILD_ENV === 'development' ? 8217 : 8217;
    await axios
      .post(KLAYTN_MOBILE_PREPARE_URL, {
        bapp: { name: 'METAKINGS' },
        type: 'execute_contract',
        chain_id: chainId,
        transaction: {
          to,
          from: accountInfo.address,
          abi: JSON.stringify(mintAbi.abi),
          value: caver.utils.toPeb(String(price), 'KLAY'),
          params,
        },
      })
      .then(async (response) => {
        const { request_key } = response.data;
        window.location.href = `kaikas://wallet/api?request_key=${request_key}`;

        let requestCount = 0;
        let preparedCount = 0;

        let timerId = setInterval(async () => {
          await axios
            .get(`https://api.kaikas.io/api/v1/k/result/${request_key}`)
            .then(async (res) => {
              loadingOpenModal();
              // if (requestCount === 10 || preparedCount === 10) {
              //   preparedCount = 0;
              //   requestCount = 0;
              //   openModal();
              //   clearInterval(timerId);
              //   loadingCloseModal();
              //   await saveTxId(null, tag);
              //   return;
              // }

              if (res.data.status === 'canceled') {
                openModal();
                clearInterval(timerId);
                loadingCloseModal();
                await saveTxId(null, tag);

                return;
              }

              if (res.data.status === 'prepared') {
                preparedCount = preparedCount + 1;
              }

              if (res.data.status === 'requested') {
                requestCount = requestCount + 1;
              }
              // loadingOpenModal();
              if (res.data.status === 'completed') {
                clearInterval(timerId);

                await saveTxId(res.data.result.tx_hash, tag);
                await bootstrap();
                loadingCloseModal();

                setCount(1);
                successOpenModal();
              } else if (res.data.status === 'error') {
                alert(request_key);
                clearInterval(timerId);

                await saveTxId(res.data.result.tx_hash, tag);
                loadingCloseModal();
                setCount(1);
                openModal();
              }
            })
            .catch(async (err) => {
              alert(jsonStr(err));
              openModal();
              clearInterval(timerId);
              await saveTxId(null, tag);
            });
        }, 1000);
      })
      .catch(async (err) => {
        alert(err);
        await saveTxId(null, tag);
      });
  };

  const syncMintTime = (time: number) => {
    return dayjs(time).format('YYYY-MM/DD HH:mm');
  };

  const onMintHandler = async (contractAddress: string) => {
    const walletType = localStorage.getItem(walletLoginKey);
    // console.log(walletType, 'walletType', accountInfo);

    if (accountInfo?.walletidx === 0 && !window.klaytn && !isMobile()) {
      // alert('kaikas 지갑을 설치해주세요.');
      setAlertMessage('kaikas 지갑을 설치해주세요.');
      alertOpenModal();
      return;
    }
    if (!isLogin || !accountInfo?.address) {
      // alert('kaikas 지갑을 연결해주세요.');
      setAlertMessage('지갑을 연결해주세요.');
      alertOpenModal();
      return;
    }
    let tagInfo = '';
    localStorage.removeItem('tag');

    if (accountInfo?.walletidx > -1 && accountInfo?.walletidx === 1) {
      executeContractKlipPc({
        caver,
        contractAddress,
        setAlertMessage,
        alertOpenModal,
        whiteListOpenModal,
        accountInfo,
        count,
        price,
        setMintQr,
        openMintQrModal,
        setQrTitle,
        loadingOpenModal,
        openModal,
        setCount,
        successOpenModal,
        bootstrap,
        loadingCloseModal,
        closeMintQrModal,
      });
      return;
    }

    if (!isMobile() && accountInfo?.walletidx === 0) {
      const walletAddress = await window.klaytn.selectedAddress;

      if (walletAddress !== accountInfo?.address) {
        setAlertMessage(
          'kaikas 지갑주소와 실제 로그인 주소가 다릅니다. 다시 로그인해주세요.'
        );
        alertOpenModal();
        onSignOut();
        return;
      }
    }

    try {
      setMintLoading(true);
      const abiData = await getAbiData('');
      const mintContract = caver.contract.create(abiData.abi, contractAddress);
      // const test = await mintContract.methods.balanceOf(accountInfo?.address).call();
      // console.log(test, 'test');
      const isMintPossible = await mintContract.methods._paused().call();

      if (isMintPossible) {
        setAlertMessage('원활한 민팅을 위해 민팅이 잠시 중지되었습니다.');
        alertOpenModal();
        return;
      }
      // const mintCheck = await mintContract.methods.getEnabled().call({ from: accountInfo?.address });
      const whiteLists = (await getWhiteLists()) as {
        addresses: string[];
        enabled: boolean;
      };
      if (whiteLists.enabled) {
        // const whiteLists = await mintContract.methods.getWhiteList().call({ from: accountInfo?.address });
        const lowerWhiteLists = whiteLists.addresses.map((item: any) =>
          item.toLowerCase()
        );
        if (!lowerWhiteLists.includes(accountInfo.address.toLowerCase())) {
          whiteListOpenModal();
          return;
        }
      }

      const { tag, gasFee } = await getMintData(count);
      localStorage.setItem('tag', tag);

      tagInfo = tag;
      if (isMobile()) {
        await executeContract({
          to: contractAddress,
          tag,
        });
        return;
      }

      const response = await caver.klay.sendTransaction({
        type: 'SMART_CONTRACT_EXECUTION',
        from: accountInfo.address,
        to: contractAddress,
        gas: String(gasFee),
        data: mintContract.methods.mint(count).encodeABI(),
        value: caver.utils.toPeb(String(price), 'KLAY'),
      });

      if (response.status) {
        await saveTxId(response.transactionHash, tag);
        await bootstrap();
        setCount(1);
        successOpenModal();
        // const projectInfo = await getNftProject(currentProject!.idx);
        // const assetsInfo = await getNftAssetsNftLists(currentProject!.idx);
        // successOpenModal();
        // setCurrentNftProject(projectInfo);
        // setNftAssetsLists(assetsInfo);
        setMintLoading(false);
      } else {
        await saveTxId(response.transactionHash, tag);
        await bootstrap();
        setCount(1);
        openModal();
        setMintLoading(false);
      }
      //
      //   if (!response.status) {
      //     await saveTxId(currentProject!.idx, idx, response.transactionHash, tag);
      //   }
    } catch (err: any) {
      console.log(err, 'err');
      if (err?.message.includes('WalletMiddleware - Invalid "from" address')) {
        alert(
          'kaikas 지갑이 연결이 안되어 로그아웃을 합니다. 다시 로그인 해주세요.'
        );
        onSignOut();
        return;
      }

      if (
        err?.message.includes(
          'Error: Kaikas Tx Signature: User denied transaction signature.'
        )
      ) {
        openModal();
        await saveTxId(null, tagInfo);
        console.log('ererer');
        return;
      }
      if (
        err?.message.includes(
          'Returned error: Error: WalletMiddleware - Invalid "from" address.'
        )
      ) {
        alert(
          'kaikas 지갑이 연결이 안되어 로그아웃을 합니다. 다시 로그인 해주세요.'
        );
        onSignOut();
        return;
      }
      await saveTxId(null, tagInfo);
      // onFailMintHandler('민팅 요청에 실패 했습니다. 다시 시도해주세요.');
      openModal();
    } finally {
      setMintLoading(false);
    }
  };

  const bootstrap = async () => {
    try {
      const result = await getNftProjectLists();
      const currentBlock = await caver.klay.getBlockNumber();
      setMaxMint(result.maxmint);
      setProject(result);
      setCurrentBlockNumber(currentBlock);
      setPrice(result?.price);
    } catch (err) {
      if (axios.isAxiosError(err)) {
        console.log(err, 'bootstrap');
      }

      // alert(err);
    }
  };

  const saveTagIdWhenCaceled = async () => {
    if (window.performance) {
      if (window.performance.navigation.type === 1) {
        const tagId = localStorage.getItem('tag');
        if (tagId) {
          await saveTxId(null, tagId);
          localStorage.removeItem('tag');
        }
      }
    }
  };

  useEffect(() => {
    saveTagIdWhenCaceled();
    bootstrap();
  }, []);

  return (
    <>
      <article>
        <section className='minting inner-box'>
          <h2 className='page-tit'>Minting Page</h2>
          <div className='mint-cont flex'>
            <FigureWrapper>
              <img src={project?.thumbnail} alt='' />
            </FigureWrapper>
            <div className='mint-txt'>
              {/*<p className="address">*/}
              {/*  {project?.blockchain?.contract.slice(0, 7)}...{project?.blockchain?.contract.slice(-3)}*/}
              {/*</p>*/}
              <h3 className='mint-tit'>{project?.title}</h3>
              <PWrapper>
                ※ 민팅은 <a>카이카스 지갑으로만</a> 참여가 가능합니다.
              </PWrapper>
              <div className='progress'>
                {/*<em>*/}
                {/*  온라인 민팅*/}
                {/*  <i>*/}
                {/*    {syncMintTime(project?.start!)} ~ {syncMintTime(project?.finish!)}*/}
                {/*  </i>*/}
                {/*</em>*/}
                <div className='graph'>
                  <SpanWrapper
                    className='bar'
                    width={`${(project?.minted! / project?.maximum!) * 100}%`}
                  >
                    <i className='num'>{project?.minted}</i>
                  </SpanWrapper>
                </div>
                <em style={{ position: 'relative', bottom: '-34px' }}>
                  팔린 개수
                </em>
              </div>
              <div className='mint-block-no'>
                <h4 className='small-tit'>Minting Block No.</h4>
                <div>
                  <span>Current</span>
                  <strong># {currentBlockNumber?.toLocaleString()}</strong>
                </div>
                <div>
                  <span>Minting Starts at</span>
                  <strong># {project?.blockNumber.toLocaleString()}</strong>
                </div>
                <p className='desc'>
                  * 보다 자세한 정보는{' '}
                  <a
                    href='https://scope.klaytn.com/'
                    target='_blank'
                    rel='noreferrer'
                  >
                    Klaytnscope
                  </a>
                  에서 확인하실 수 있습니다.
                </p>
              </div>
              <div className='mint-price'>
                <div>
                  {/*<h4 className="small-tit">Price (per {price} MKZ)</h4>*/}
                  <div className='klay'>
                    <strong>{price}</strong>KLAY
                  </div>
                </div>
                <div className='flex'>
                  <span className='quantity'>
                    <strong>
                      <i>{maxMint}</i>per Transaction
                    </strong>
                    <span className='guide-txt'>
                      [ 트랜잭션 당 구매 가능 수량 ]
                    </span>
                  </span>
                  {/*<span className="quantity">*/}
                  {/*  <strong>*/}
                  {/*    <i>100</i>per Wallet*/}
                  {/*  </strong>*/}
                  {/*  <span className="guide-txt">[ 지갑 당 구매 가능 수량 ]</span>*/}
                  {/*</span>*/}
                </div>
              </div>
              <div className='mint-btn'>
                <div className='number'>
                  <button
                    className='btn-remove'
                    onClick={() => {
                      if (count === 1) {
                        return;
                      }
                      setCount(count - 1);
                      setPrice(project?.price! * (count - 1));
                    }}
                  >
                    <img src={img_btn_remove} alt='' />
                  </button>
                  <span>{count}</span>
                  <button
                    onClick={() => {
                      if (count + 1 + project?.minted! > project?.maximum!) {
                        setAlertMessage(`남은 재고가 부족합니다.`);
                        alertOpenModal();
                        return;
                      }

                      if (maxMint && count < maxMint) {
                        setCount(count + 1);
                        setPrice(project?.price! * (count + 1));
                      }
                    }}
                    className='btn-add'
                  >
                    <img src={img_btn_add} alt='' />
                  </button>
                </div>
                <a
                  style={{ cursor: 'pointer' }}
                  onClick={async () => {
                    const currentTime = dayjs(new Date()).unix() * 1000;
                    const startTime = dayjs(project?.start).unix() * 1000;
                    const endTime = dayjs(project?.finish).unix() * 1000;
                    if (currentTime < startTime || currentTime > endTime) {
                      setAlertMessage('민팅시간이 아닙니다.');
                      alertOpenModal();
                      return;
                    }

                    if (project?.minted! >= project?.maximum!) {
                      setAlertMessage('이미 민팅이 완료 되었습니다.');
                      alertOpenModal();
                      return;
                    }

                    if (project?.blockchain.contract) {
                      await onMintHandler(project?.blockchain.contract);
                    }
                  }}
                  className='btn-mint'
                >
                  MINT
                </a>
              </div>
            </div>
          </div>
        </section>
        <ModalPortal>
          <MintFailModal
            text='거래에 실패했습니다. 다시 시도해주세요.'
            closeHandler={closeModal}
          />
        </ModalPortal>

        <SuccessModal>
          <SuccessMintModal closeHandler={successCloseModal} />
        </SuccessModal>

        <AlertModal>
          <InfoModal text={alertMessage} closeHandler={alertCloseModal} />
        </AlertModal>

        <LoadingModalPortal>
          <LoadingInfoModal />
        </LoadingModalPortal>

        <WhiteListModalPortal>
          <WhiteListsFailModal closeHandler={whiteListsCloseModal} />
        </WhiteListModalPortal>

        <MintQrPortal>
          <LoginQrModal
            closeModal={closeMintQrModal}
            qrString={mintQr}
            qrMessage={qrTitle}
          />
        </MintQrPortal>
      </article>
    </>
  );
};

export default MintingPage;
