import styled from '@emotion/styled';
import { MyTier } from '@api/app';
import { FC } from 'react';

const LiWrapper = styled.li`
  text-decoration: underline;
  a {
    color: #fff !important;
    font-weight: bold;
  }
`;

interface Props {
  tier: 'welcome' | 'gold' | 'platinum' | 'black' | null;
  myNftInfo: MyTier | null;
}

const RegularBenefit: FC<Props> = ({ myNftInfo, tier }) => {
  return (
    <>
      {/* {myNftInfo?.nftCount !== 0 && <strong>상시 사용 가능 혜택</strong>} */}

      <ul>
        {myNftInfo?.nftCount === 0 ? (
          <LiWrapper>
            <a
              href='https://secrettown.gitbook.io/metakingz2024'
              target='_blank'
              rel='noreferrer'
            >
              가이드 바로가기
            </a>
          </LiWrapper>
        ) : (
          <>
            {/* <li>· 코코넛박스 연간 관람권 </li>
            <li>· 코코넛박스 홀더 전용 라운지 </li>
            <li>· 코코넛박스 F&B 20% 할인 </li>
            <li>· 코코발리 BBQ 10% 할인 </li>
            <li>· 발리문 음료 서비스 </li>
            <li>· 팜팜발리 메뉴 서비스 </li>
            <li>· 퍼플스테이 숙박권 50% 할인권 </li>*/}
            <br />
            <LiWrapper>
              <a
                href='https://secrettown.gitbook.io/metakingz2024'
                target='_blank'
                rel='noreferrer'
              >
                가이드 바로가기
              </a>
            </LiWrapper>
          </>
        )}
      </ul>
    </>
  );
};

export default RegularBenefit;
