// @ts-ignore
import { prepare, request, getResult, getCardList } from 'klip-sdk';

import { Link, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { HashLink } from 'react-router-hash-link';

import img_btn_back from '@assets/images/btn_back.png';
import img_nav_globe from '@assets/images/ico_nav-globe.png';
import img_sns_tel from '@assets/images/ico_h-sns-tel.png';
import img_sns_insta from '@assets/images/ico_h-sns-insta.png';
import img_sns_discord from '@assets/images/ico_h-sns-discord.png';
import img_sns_kakao from '@assets/images/ico_h-sns-kakao.png';
import img_nav_pfp from '@assets/images/img_nav-pfp.png';
import img_btn_copy from '@assets/images/btn_copy.png';
import img_wallet_c from '@assets/images/ico_wallet_txt.png';

import { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { browserName, browserVersion } from 'react-device-detect';

import IConBox from '@components/IConBox';
import MobileIConBox from '@components/MobileIConBox';
import { APICore, getUserFromSession, setAuthorization } from '@api/index';
import Caver from 'caver-js';
import { getMyInfo, signIn, verifyUser } from '@api/account';
import { useRecoilState } from 'recoil';
import {
  accountInfoAtom,
  isLoginAtom,
  isMintLoadingAtom,
  loginWalletAtom,
} from '@recoil/atoms';
import axios from 'axios';
import { KLAYTN_MOBILE_PREPARE_URL } from '@pages/MyInfo';
import { isMobile } from '@pages/Minting';
import copy from 'copy-to-clipboard';
import useModal from '@hooks/useModal';
import InfoModal from '@components/Modal/Info';
import SelectWalletModal from '@components/Modal/SelectWallet';
import LoginQrModal from '@components/Modal/LoginQr';
import {
  connectKlipMobileWallet,
  jsonStr,
  KAIKAS,
  KLIP,
  walletLoginKey,
} from '@utils/klip/connectKlipMobileWallet';
import klipConnectWallet, {
  bappName,
  failLink,
  pcMobileLogin,
  successLink,
} from '@utils/klip/connectKlipWallet';
const caver = new Caver(window.klaytn);

export const KAIKAS_WALLET_IDX = 0;
export const KLIP_WALLET_IDX = 1;

const MobileNavContainer = styled.div<{ click: boolean }>`
  @media (max-width: 1280px) {
    right: ${(props) => (props.click ? '0px' : '-100%')};
    transition: all 0.25s;
  }
`;

const ImgWrapper = styled.img`
  border-radius: 50%;
  width: 100%;
  object-fit: cover;
  height: 100%;
`;

const headerItems = [
  { title: '홈', link: '/#' },
  { title: '세계관', link: '/#metaWorld' },
  { title: '로드맵', link: '/#metaRoadmap' },
  { title: '멤버쉽', link: '/#metaMembership' },
  // { title: 'NFT 전시관', link: '/#metaMembership' },
  { title: '구성원', link: '/#metaTeam' },
  { title: '파트너', link: '/#metaPartner' },
  { title: '문의', link: '/#metaFaq' },
];

const api = new APICore();

const Header = () => {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [isLogin, setIsLogin] = useRecoilState(isLoginAtom);
  const [accountInfo, setAccountInfo] = useRecoilState(accountInfoAtom);
  const [isProfileHover, setIsProfileHover] = useState(false);
  const [isHomeMenu, setIsHomeMenu] = useState(false);
  const [loading, setLoading] = useState(false);
  const [infoMessage, setInfoMessage] = useState('');
  const [loginQr, setLoginQr] = useState('');
  const [qrMessage, setQrMessage] = useState('');
  const [loginWallet, setLoginWallet] = useRecoilState(loginWalletAtom);
  const { ModalPortal, openModal, closeModal } = useModal();
  const {
    ModalPortal: SelectModal,
    openModal: openSelectModal,
    closeModal: closeSelectModal,
  } = useModal();
  const {
    ModalPortal: LoginQrModalPortal,
    openModal: openLoginModal,
    closeModal: closeLoginModal,
  } = useModal();

  const walletLoginBtnRef = useRef<HTMLLIElement | null>(null);

  const [mintLoading, setMintLoading] = useRecoilState(isMintLoadingAtom);

  const onSignOut = () => {
    if (mintLoading) return;
    localStorage.removeItem('isLogin');
    localStorage.removeItem(walletLoginKey);
    api.setLoggedInUser(null);

    window.location.reload();
  };

  const handleConnectWallet = async () => {
    if (isMobile()) {
      connectMobile();
      return;
    }

    if (!window.klaytn) {
      alert('클레이튼 지갑을 설치해주세요.');
      return;
    }

    if (loading) return;

    const caver = new Caver(window.klaytn);
    try {
      setLoading(true);
      const accounts = await window.klaytn.enable();
      const publicAddress = accounts[0].toLowerCase();
      const walletidx = 0;
      const signInData = await signIn(publicAddress, walletidx);
      const signMessage = signInData.sigmessage.split(':')[0] + ':';
      // const signedMessage = await caver.rpc.klay.sign(publicAddress, 'my message');

      const signature = await caver.klay.sign(
        `${signMessage} ${signInData.nonce}`,
        publicAddress
      );

      const body = { address: publicAddress, signature, walletidx: 0 };
      await verifyUser(body, signInData.token);
      setAuthorization(signInData.token);
      const myInfoData = await getMyInfo();

      if (JSON.parse(myInfoData.nonceVerified)) {
        api.setLoggedInUser(signInData.token);
        setAccountInfo({
          address: myInfoData.address,
          uid: myInfoData.uid,
          nonceVerified: myInfoData.nonceVerified,
          name: myInfoData.name,
          profile: myInfoData.profile,
          walletidx: myInfoData.walletidx,
        });
        setIsLogin(true);
        localStorage.setItem('isLogin', 'true');
        setLoginWallet('kaikas');
        localStorage.setItem(walletLoginKey, KAIKAS);
      }
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  const onCloseNav = () => {
    const content = document.querySelector('#contents')!;
    const html = document.querySelector('html')!;
    content.classList.remove('dim');
    html.classList.remove('nav-open');
    setIsOpen(!isOpen);
    setIsHomeMenu(false);
  };
  const mobileSign = async (address: string) => {
    const api = new APICore();
    try {
      const publicAddress = address.toLowerCase();
      const walletidx = 0;
      const signInData = await signIn(publicAddress, walletidx);
      const signMessage = signInData.sigmessage.split(':')[0] + ':';
      const body = {
        type: 'sign',
        bapp: { name: 'METAKINGZ' },
        sign: { message: `${signMessage} ${signInData.nonce}` },
      };
      const { request_key } = (await axios
        .post(`${KLAYTN_MOBILE_PREPARE_URL}`, body)
        .then((resp) => resp.data)) as any;

      window.location.href = `kaikas://wallet/api?request_key=${request_key}`;
      let requestCount = 0;
      let preparedCount = 0;
      let timerId = setInterval(async () => {
        await axios
          .get(`https://api.kaikas.io/api/v1/k/result/${request_key}`)
          .then(async (res) => {
            if (res.data.status === 'canceled') {
              clearInterval(timerId);
              alert('다시 시도해주세요.');
              return;
            }

            if (requestCount === 10 || preparedCount === 10) {
              preparedCount = 0;
              requestCount = 0;
              clearInterval(timerId);
              // alert('로그인을 다시 시도해주세요.');

              return;
            }

            if (res.data.status === 'prepared') {
              preparedCount = preparedCount + 1;
            }

            if (res.data.status === 'requested') {
              requestCount = requestCount + 1;
            }

            if (res.data.status === 'completed') {
              const body = {
                address: publicAddress,
                signature: res.data.result.signed_data,
                walletidx: 0,
              };
              await verifyUser(body, signInData.token);
              setAuthorization(signInData.token);
              const myInfoData = await getMyInfo();
              if (JSON.parse(myInfoData.nonceVerified)) {
                api.setLoggedInUser(signInData.token);
                setAccountInfo({
                  address: myInfoData.address,
                  uid: myInfoData.uid,
                  nonceVerified: myInfoData.nonceVerified,
                  name: myInfoData.name,
                  profile: myInfoData.profile,
                  walletidx: myInfoData.walletidx,
                });
                setIsLogin(true);
                localStorage.setItem('isLogin', 'true');
                localStorage.setItem(walletLoginKey, KAIKAS);
                setLoginWallet('kaikas');
                clearInterval(timerId);
              }
            }
          })
          .catch((err) => {
            clearInterval(timerId);
            alert('error가 발생했습니다. 다시 로그인해주세요.');
          });
      }, 1000);
    } catch (err) {
      console.log(err, 'err');
    }
  };

  const connectMobile = async () => {
    const isSafariMobile = 'Mobile Safari' === browserName;
    const isChrome = 'Chrome' === browserName;

    if (!(isSafariMobile || isChrome)) {
      setInfoMessage(
        '모바일에서는 사파리, 크롬 브라우저에서만 접속이 가능합니다'
      );
      openModal();
      onCloseNav();
      return;
    }

    // 8217
    const chainId =
      process.env.REACT_APP_BUILD_ENV === 'development' ? 8217 : 8217;

    try {
      const body = {
        bapp: {
          name: 'METAKINGZ',
        },
        type: 'auth',
        callback: {
          success: 'https://www.google.com/search?q=success',
          fail: 'https://www.google.com/search?q=fail',
        },
        chain_id: chainId,
      };
      const result = await axios
        .post('https://api.kaikas.io/api/v1/k/prepare', body)
        .then((resp) => {
          return resp.data as any;
        })
        .catch((err) => console.log(err));
      const { request_key } = result;
      window.location.href = `kaikas://wallet/api?request_key=${request_key}`;

      let requestCount = 0;
      let preparedCount = 0;
      let comCount = 0;
      let timerId = setInterval(async () => {
        await axios
          .get(`https://api.kaikas.io/api/v1/k/result/${request_key}`)
          .then(async (res) => {
            if (res.data.status === 'canceled') {
              alert('로그인을 다시 시도해주세요.');
              clearInterval(timerId);
              return;
            }

            if (requestCount === 5 || preparedCount === 5) {
              preparedCount = 0;
              requestCount = 0;
              clearInterval(timerId);
              // alert('로그인을 다시 시도해주세요.');

              return;
            }

            if (res.data.status === 'prepared') {
              preparedCount = preparedCount + 1;
            }

            if (res.data.status === 'requested') {
              requestCount = requestCount + 1;
            }

            if (res.data.status === 'completed') {
              clearInterval(timerId);

              if (comCount === 3) {
                clearInterval(timerId);
                // alert('로그인을 다시 시도해주세요.');
                comCount = 0;
                return;
              }

              comCount = comCount + 1;

              await mobileSign(res.data.result.klaytn_address);
              setAccountInfo(res.data.result.klaytn_address);
            }
          })
          .catch((err) => {
            alert(err);
            alert('error');
            clearInterval(timerId);
          });
      }, 1000);
    } catch (err) {
      console.log(err);
      alert(err);
    }
  };

  const onCopy = () => {
    copy(accountInfo?.address);
    alert('Copied');
  };

  const openMenu = () => {
    const content = document.querySelector('#contents')!;
    const html = document.querySelector('html')!;

    content.setAttribute('className', 'dim');
    html.setAttribute('className', 'nav-open');
    setIsOpen(!isOpen);
  };

  const bootstrap = async () => {
    window.klaytn.on('accountsChanged', async function (accounts: string[]) {
      const isLocalLogin = JSON.parse(localStorage.getItem('isLogin')!);

      if (!accounts.length) {
        onSignOut();
        return;
      }

      if (
        accounts.length &&
        isLocalLogin &&
        accountInfo?.address !== accounts[0]
      ) {
        const publicAddress = accounts[0].toLowerCase();
        try {
          const walletidx = 0;
          const signInData = await signIn(accounts[0], walletidx);

          if (!JSON.parse(signInData.nonceVerified as string)) {
            const signMessage = signInData.sigmessage.split(':')[0] + ':';

            const signature = await caver.klay.sign(
              `${signMessage} ${signInData.nonce}`,
              publicAddress
            );
            const body = { address: publicAddress, signature, walletidx: 0 };

            await verifyUser(body, signInData.token);
            setAuthorization(signInData.token);

            const myInfoData = await getMyInfo();
            if (JSON.parse(myInfoData.nonceVerified)) {
              api.setLoggedInUser(signInData.token);
              setAccountInfo({
                address: myInfoData.address,
                uid: myInfoData.uid,
                nonceVerified: myInfoData.nonceVerified,
                name: myInfoData.name,
                profile: myInfoData.profile,
                walletidx: myInfoData.walletidx,
              });
              setIsLogin(true);
              localStorage.setItem('isLogin', 'true');
            }
          } else {
            setAuthorization(signInData.token);
            api.setLoggedInUser(signInData.token);

            const { address, uid, nonceVerified, name, profile, walletidx } =
              signInData;
            setAccountInfo({
              address,
              uid,
              nonceVerified,
              name,
              profile,
              walletidx,
            });
          }
        } catch (err) {
          console.log(err, 'err');
        }
      }
    });

    window.klaytn.on('networkChanged', function (data: any) {
      // `networkChanged` event is only useful when auto-refresh on network is disabled
      // Otherwise, Kaikas will auto-reload pages upon network change
    });
  };

  useEffect(() => {
    const getToken = getUserFromSession();
    const isLogin = JSON.parse(localStorage.getItem('isLogin')!);
    if (isLogin) {
      setIsLogin(true);
    } else {
      setIsLogin(false);
    }
    bootstrap();
  }, []);

  return (
    <>
      <header>
        <section
          className='header-inner'
          onMouseLeave={() => {
            setIsProfileHover(false);
          }}
        >
          <h1>
            <Link to='/'>Metakingz logo</Link>
          </h1>
          {/*  <nav>
            <ul className="gnb">
              {headerItems.map((item, i) => (
                <li key={i} className={classNames({ curr: item.title === '홈' })}>
                  <HashLink smooth to={item.link}>
                    {item.title}
                  </HashLink>
                </li>
              ))}
            </ul>
          </nav> */}
          <div className='pc'>
            {isLogin && accountInfo?.address ? (
              <div
                className='connected'
                onMouseOver={() => {
                  setIsProfileHover(true);
                }}
              >
                <div className='nick-wrap'>
                  <div className='nick-thumb'>
                    <ImgWrapper src={accountInfo?.profile} />
                  </div>
                  <div className='nick-txt'>
                    <button
                      className={classNames({ down: true, on: isProfileHover })}
                    >
                      {accountInfo?.name}&nbsp;님
                    </button>
                  </div>
                </div>
                <div
                  className='connect-select select-type02'
                  style={{ display: isProfileHover ? 'block' : 'none' }}
                >
                  <ul>
                    <li className='ico-benefit'>
                      <Link to='/profile/membership'>혜택 관리</Link>
                    </li>
                    <li className='ico-logout'>
                      <a style={{ cursor: 'pointer' }} onClick={onSignOut}>
                        로그아웃
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            ) : (
              <a
                onClick={openSelectModal}
                style={{ cursor: 'pointer' }}
                className='btn-wallet'
                data-popup-target='#connectWallet'
              >
                <img src={img_wallet_c} alt='지갑연결' />
              </a>
            )}

            <IConBox />
          </div>
          <MobileIConBox openMenu={openMenu} />
        </section>
      </header>
      {/*    <button
        className='btn-menu'
        onClick={() => {
          setIsHomeMenu(true);
          openMenu();
        }}
      >
        button
      </button> */}
      <MobileNavContainer
        click={isOpen}
        className={classNames(
          'mobile-nav',
          { mylogin: isLogin && !isHomeMenu },
          { 'wallet-connect': !isLogin && !isHomeMenu }
        )}
      >
        <a
          style={{ cursor: 'pointer' }}
          onClick={() => {
            onCloseNav();
            setIsProfileHover(false);
          }}
          className='close'
        >
          <img src={img_btn_back} alt='' />
        </a>
        <div className='default'>
          <div className='select-box lang'>
            {/*<button className="down">*/}
            {/*  <img src={img_nav_globe} alt="" />*/}
            {/*</button>*/}
            <div>
              <ul>
                <li>
                  <a href='#'>KOR</a>
                </li>
                <li>
                  <a href='#'>ENG</a>
                </li>
              </ul>
            </div>
          </div>
          <div
            className='gnb-list'
            onMouseLeave={() => {
              setIsProfileHover(false);
            }}
          >
            <ul className='gnb'>
              {headerItems.map((item, i) => (
                <li
                  key={i}
                  className={classNames({
                    curr: location.pathname === item.link,
                  })}
                  onClick={onCloseNav}
                >
                  <HashLink to={item.link}>{item.title}</HashLink>
                </li>
              ))}
            </ul>
          </div>
          {/*<Link to="/profile" className="btn-benefit">*/}
          {/*  <span>혜택관리</span>*/}
          {/*</Link>*/}

          <ul className='sns'>
            <li>
              <a
                href='https://t.me/METAKINGZCommunity'
                target='_blank'
                rel='noreferrer'
              >
                <img src={img_sns_tel} alt='' />
              </a>
            </li>
            <li>
              <a
                href='https://www.instagram.com/metakingz_official'
                target='_blank'
                rel='noreferrer'
              >
                <img src={img_sns_insta} alt='' />
              </a>
            </li>
            <li>
              <a
                href='https://discord.com/invite/AdBS3NUgU9'
                target='_blank'
                rel='noreferrer'
              >
                <img src={img_sns_discord} alt='' />
              </a>
            </li>
            <li>
              <a
                href='https://open.kakao.com/o/gC3eRFJe'
                target='_blank'
                rel='noreferrer'
              >
                <img src={img_sns_kakao} alt='' />
              </a>
            </li>
          </ul>
        </div>
        {isLogin ? (
          <div className='nickname'>
            <div className='info'>
              <figure>
                <img
                  style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                  src={accountInfo?.profile}
                  alt=''
                />
              </figure>
              <div className='txt'>
                <strong>{accountInfo?.name} 님</strong>
                <div>
                  <em>{accountInfo?.address.slice(0.7)}...</em>
                  <button onClick={onCopy}>
                    <img src={img_btn_copy} alt='' />
                  </button>
                </div>
              </div>
            </div>
            <ul className='mylist'>
              <li>
                <Link to='/profile/membership' onClick={onCloseNav}>
                  혜택관리
                </Link>
              </li>
              <li>
                <Link to='/profile/metakingz' onClick={onCloseNav}>
                  나의 메타킹즈
                </Link>
              </li>
            </ul>

            <a
              onClick={onSignOut}
              className='btn-logout'
              style={{ cursor: 'pointer' }}
            >
              로그아웃
            </a>
          </div>
        ) : (
          <div className='wallet'>
            <strong>지갑 연결</strong>
            <p>연결 할 지갑을 선택해주세요.</p>
            <ul>
              <li
                className='kaikas'
                onClick={() => {
                  handleConnectWallet();
                }}
              >
                <a style={{ cursor: 'pointer' }}>
                  <span>Kaikas 지갑으로 연결</span>
                </a>
              </li>
              <li
                className='klip'
                onClick={async () => {
                  if (isMobile()) {
                    connectKlipMobileWallet({
                      setAccountInfo,
                      setIsLogin,
                      setLoginWallet,
                      setQrMessage,
                    });
                    return;
                  } else {
                    try {
                      onCloseNav();
                      const res = await prepare.auth({
                        bappName,
                        successLink,
                        failLink,
                      });
                      openLoginModal();

                      setQrMessage('Klip 로그인');
                      setLoginQr(
                        `https://klipwallet.com/?target=/a2a?request_key=${res.request_key}`
                      );
                      await request(res.request_key, () => {
                        console.log('pc');
                      });
                      let authTimer = setInterval(async () => {
                        const _result = await getResult(res.request_key);
                        if (_result.status === 'canceled') {
                          clearInterval(authTimer);
                          return;
                        }

                        if (_result.status === 'completed') {
                          try {
                            clearInterval(authTimer);
                            const selectedAddress =
                              _result.result.klaytn_address;

                            if (selectedAddress) {
                              const publicAddress =
                                selectedAddress.toLowerCase();
                              const signInData = await signIn(
                                publicAddress,
                                KLIP_WALLET_IDX
                              );
                              setAuthorization(signInData.token);
                              const myInfoData = await getMyInfo();

                              api.setLoggedInUser(signInData.token);
                              setAccountInfo({
                                address: myInfoData.address,
                                uid: myInfoData.uid,
                                nonceVerified: myInfoData.nonceVerified,
                                name: myInfoData.name,
                                profile: myInfoData.profile,
                                walletidx: myInfoData.walletidx,
                              });

                              setIsLogin(true);
                              localStorage.setItem('isLogin', 'true');
                              setLoginWallet('klip');
                              localStorage.setItem(walletLoginKey, KLIP);
                              closeLoginModal();
                              // await pcMobileLogin({
                              //   selectedAddress,
                              //   setQrMessage,
                              //   setLoginQr,
                              //   setAccountInfo,
                              //   setIsLogin,
                              //   setLoginWallet,
                              //   closeModal,
                              //   closeLoginModal,
                              // });
                            }
                          } catch (err) {
                            alert(jsonStr(err));
                          }
                        }
                      }, 1000);
                    } catch (err) {}
                  }
                }}
              >
                <a href='#'>
                  <span>카카오 Klip 지갑으로 연결</span>
                </a>
              </li>
            </ul>
          </div>
        )}
      </MobileNavContainer>

      <ModalPortal>
        <InfoModal text={infoMessage} closeHandler={closeModal} />
      </ModalPortal>

      <SelectModal>
        <SelectWalletModal
          closeModal={closeSelectModal}
          kaikasConnectHadnler={handleConnectWallet}
          klipConnectHandler={() => console.log('klip')}
          loginOpenModal={openLoginModal}
          setLoginQr={setLoginQr}
          closeQrModal={closeLoginModal}
          setQrMessage={setQrMessage}
          walletLoginBtnRef={walletLoginBtnRef}
        />
      </SelectModal>

      <LoginQrModalPortal>
        <LoginQrModal
          closeModal={closeLoginModal}
          qrString={loginQr}
          qrMessage={qrMessage}
        />
      </LoginQrModalPortal>
    </>
  );
};

export default Header;
