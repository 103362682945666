import { FC, useEffect, useState } from 'react';
import { cursorPointerStyle } from '@utils/stlyes';
import { BenefitHistory, getBenefitHistory } from '@api/app';
import { countEnableCoupon } from '@components/WelcomeCard';
import moment from 'moment';

interface Props {
  closeModal: () => void;
  type: number | null;
  item: any;
}

const HistoryModal: FC<Props> = ({ closeModal, type, item }) => {
  const [history, setHistory] = useState<BenefitHistory[]>([]);

  const bootstrap = async () => {
    if (type) {
      const result = await getBenefitHistory(type);
      setHistory(result);
    }
  };

  useEffect(() => {
    bootstrap();
  }, [type]);

  return (
    <div className="popup-wrap" style={{ display: 'block' }}>
      <div className="dim" />
      <div className="pop-type01 pop-history" style={{ display: 'block' }}>
        <strong className="pop-tit black text-l">사용내역 확인하기</strong>
        <div className="qr">
          <img src="" alt="" />
        </div>
        <div className="history-wrap">
          <p>{item?.couponTypeName}</p>
          <ul className="count-number">
            <li>
              <strong>남은 개수</strong>
              <strong>
                <em>{item.couponList.length === 0 ? 0 : countEnableCoupon(item.couponList)}</em> 개
              </strong>
            </li>
            <li>
              <strong>제공 개수</strong>
              <strong>
                <em>{item?.couponList.length}</em> 개
              </strong>
            </li>
          </ul>
          <ul className="count-date">
            {history.map((data, i) => (
              <li key={i}>
                <span>{moment(data?.userDate).format('YY.MM.DD ddd HH:mm:ss')}</span>
                <strong>
                  {i + 1}/{item?.couponList.length}
                </strong>
              </li>
            ))}
            {/*<li>*/}
            {/*  <span>22. 11.12 토요일 13:42:05</span>*/}
            {/*  <strong>3/4</strong>*/}
            {/*</li>*/}
            {/*<li>*/}
            {/*  <span>22. 11.20 일요일 15:26:48</span>*/}
            {/*  <strong>2/4</strong>*/}
            {/*</li>*/}
            {/*<li>*/}
            {/*  <span>22. 12.20 화요일 16:18:33</span>*/}
            {/*  <strong>1/4</strong>*/}
            {/*</li>*/}
          </ul>
        </div>
        <a style={cursorPointerStyle} onClick={closeModal} className="btn-type01 pop-close">
          닫 기
        </a>
      </div>
    </div>
  );
};

export default HistoryModal;
