import DotLoader from 'react-spinners/DotLoader';
import { CSSProperties } from 'react';

const override: CSSProperties = {
  display: 'block',
  margin: '0 auto',
  borderColor: 'red',
};

const Loading = () => {
  return (
    <div style={{ minHeight: '300px', marginTop: '130px' }}>
      <DotLoader
        color="#ef4423"
        loading={true}
        cssOverride={override}
        aria-label="Loading Spinner"
        data-testid="loader"
        size={120}
      />
    </div>
  );
};

export default Loading;
