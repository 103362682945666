import { FC } from 'react';
import img_pop_close from '@assets/images/ico_pop-close.png';

export interface ModalProps {
  text: string;
  closeHandler: () => void;
}

const InfoModal: FC<ModalProps> = ({ text, closeHandler }) => {
  return (
    <>
      <div className="popup-wrap" style={{ display: 'block' }}>
        <div className="dim" />

        <div className="pop-type01 pop-benefit" style={{ display: 'block' }}>
          <a onClick={closeHandler} className="pop-close" style={{ cursor: 'pointer' }}>
            <img src={img_pop_close} alt="" />
          </a>
          <strong className="pop-tit black ico-ntm">{text}</strong>
          {/*<p className="desc">아직은 METAKINGZ 홀더가 아니시네요</p>*/}
        </div>
      </div>
    </>
  );
};

export default InfoModal;
