import img_logo_big from '@assets/images/logo_big.png';
import { useState } from 'react';
import classNames from 'classnames';

const Footer = () => {
  const [isHover, setIsHover] = useState(false);

  return (
    <>
      <footer>
        <div className='inner-box'>
          <div className='logo'>
            <a href='#'>
              <img src={img_logo_big} alt='' />
            </a>
          </div>
          {/*<span className="privacy-link">*/}
          {/*  <a href="#">개인정보처리방침</a>*/}
          {/*  <a href="#">이메일무단수집거부</a>*/}
          {/*</span>*/}
          <div className='info'>
            <i className='mobile-br'>시크릿타운(주) | 대표: 조현규 | </i>
            <i className='mobile-br'>
              주소: 서울시 서초구 강남대로 291 남강빌딩 6층 |{' '}
            </i>
            <i className='mobile-br'>사업자번호: 220-88-90031 {/* |  */}</i>
            {/* <i className="mobile-br">Contact: 070) 4736-0004 | </i> */}
            <p></p>
            <i className='mobile-br'>
              기업 제휴 문의 :{' '}
              <a
                href='mailto:wisereport@gmail.com'
                target='_blank'
                rel='noreferrer'
              >
                wisereport@gmail.com
              </a>{' '}
              |{' '}
            </i>
            ceo@secrettown.co.kr | maxmook@naver.com
          </div>
          <div
            className={classNames('select-box', { on: isHover })}
            onMouseLeave={() => {
              setIsHover(false);
            }}
          >
            <button
              className={classNames({ on: isHover })}
              onMouseOver={() => {
                setIsHover(true);
              }}
            >
              Family Site
            </button>
            <div
              style={{
                display: isHover ? 'block' : 'none',
                fontFamily: 'SUIT',
              }}
            >
              <ul>
                <li>
                  <a
                    href='http://secrettown.co.kr/'
                    target='_blank'
                    rel='noreferrer'
                  >
                    Secret Town
                  </a>
                </li>
                <li>
                  <a
                    href='https://nftopia.gallery/'
                    target='_blank'
                    rel='noreferrer'
                  >
                    NFTOPIA
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <p className='copy'>COPYRIGHT © Secret Town. ALL RIGHTS RESERVED.</p>
        </div>
      </footer>
    </>
  );
};

export default Footer;
