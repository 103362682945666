import { Routes, Route } from 'react-router-dom';
import HomePage from '@pages/Home';
import NftGalleryPage from '@pages/NftGallery';
import MyInfoPage from '@pages/MyInfo';
import MintingPage from '@pages/Minting';
import DDayPage from '@pages/Dday';
import Metakingz from '@pages/Metakingz';
import Membership from '@pages/Membership';

export const index = {
  HOME: '/',
  NFT_GALLERY: '/nft-gallery',
  PROFILE: '/profile',
  MINT: '/mint',
  DDAY: '/dday',
  METAKINGZ: 'metakingz',
  MEMBERSHIP: 'membership',
};

const PublicRouter = () => {
  const { HOME, NFT_GALLERY, PROFILE, MINT, DDAY, MEMBERSHIP, METAKINGZ } = index;

  return (
    <>
      <Routes>
        <Route path={HOME} element={<HomePage />} />
        <Route path={NFT_GALLERY} element={<NftGalleryPage />} />
        <Route path={PROFILE} element={<MyInfoPage />}>
          <Route path={METAKINGZ} element={<Metakingz />} />
          <Route path={MEMBERSHIP} element={<Membership />} />
        </Route>
        <Route path={MINT} element={<MintingPage />} />
        <Route path={DDAY} element={<DDayPage />} />
      </Routes>
    </>
  );
};

export default PublicRouter;
