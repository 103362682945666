import img_nav_pfp from '@assets/images/img_nav-pfp.png';
import img_btn_copy from '@assets/images/btn_copy.png';
import React, { useEffect, useRef, useState } from 'react';
import { getMyInfo, updateImage, updateName, uploadImage } from '@api/account';
import { useNavigate, Outlet, useMatch } from 'react-router';
import { useRecoilState } from 'recoil';
import { accountInfoAtom } from '@recoil/atoms';
import img_input_del from '@assets/images/ico_input-del.png';
import img_minting_nft from '@assets/images/img_minting-nft.jpg';

import copy from 'copy-to-clipboard';
import classNames from 'classnames';
import useInput from '@hooks/useInput';
import { getMtNft, MyNftItem } from '@api/app';
import { APICore, isProduction } from '@api/index';
import axios from 'axios';
import useModal from '@hooks/useModal';
import InfoModal from '@components/Modal/Info';
import { FigureWrapper, isMobile } from '@pages/Minting';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import Loading from '@components/Loading';
import VerifyHolderModal from '@components/Modal/Verify';
import Metakingz from '@pages/Metakingz';
type EditType = 'read' | 'edit';
export interface MyNftResult {
  name: string;
  title: string;
  description: string;
  image: string;
}
export const KLAYTN_MOBILE_PREPARE_URL = 'https://api.kaikas.io/api/v1/k/prepare';

export const WHITE_LISTS = [
  '0xD806F8017262DD3c95B02fb54246A6aC13478e12',
  '0xD2042453ca932eB1F0C047597F7961A08d354384',
  '0xb20404aFceFE2CC78e26ff3bF8eDD03D3FeC0f50',
  '0x0f7CeD586919Bb720c9E69EB8785Ff040601de2A',
  '0xD806F8017262DD3c95B02fb54246A6aC13478e12',
  '0x901036c8263D0CD1b7f68906b7C225A09D39b413',
  '0x11A90B9Fd36FC1f3e0f7455d4360152502A16962',
  '0xd3a84CD210a99D896b56B733c4DA3a39E29220b6',
];
const SpanWrapper = styled.span`
  @media (min-width: 1280px) {
    overflow: auto !important;
    text-overflow: inherit !important;
    width: inherit !important;
    margin-right: 8px !important;
  }
`;

const DivWrapper = styled.div`
  margin-top: 24px !important;
`;

const MyInfoPage = () => {
  const navigate = useNavigate();
  const [accountInfo, setAccountInfo] = useRecoilState(accountInfoAtom);
  const [isSuccessCopy, setIsSuccessCopy] = useState(false);
  const [editMode, setEditMode] = useState<EditType>('read');
  const [nick, onChangeNick, onResetNick, setNick] = useInput('');
  const [url, setUrl] = useState('');
  const inputOpenImageRef = useRef<HTMLInputElement>(null);
  const [myNfts, setMyNfts] = useState<MyNftResult[]>([]);
  const { ModalPortal, openModal, closeModal } = useModal();
  const { ModalPortal: QrModalPortal, openModal: qrOpenModal, closeModal: qrCloseModal } = useModal();
  const [tab, setTab] = useState<'membership' | 'metakingz'>('membership');
  const metakingzMatch = useMatch('/profile/metakingz');
  const membershipMatch = useMatch('/profile/membership');

  const [isErrorMessage, setIsErrorMessage] = useState(false);

  const [isLoading, setLoading] = useState(false);

  const mobileCss = isMobile() || window.innerWidth < 1024;

  const handleImageClick = () => {
    if (editMode !== 'edit') {
      return;
    }

    if (inputOpenImageRef && inputOpenImageRef.current) {
      inputOpenImageRef.current.value = '';
      inputOpenImageRef.current.click();
    }
  };

  const handleImageUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }
    const formData = new FormData();
    const config = {
      headers: { 'Content-Type': 'multipart/form-data' },
    };

    formData.append('file', e.target.files[0]);
    try {
      const result = await uploadImage(formData);
      setUrl(result?.success[0].original.url);
    } catch (err) {
      console.log(err, 'err');
      alert('에러');

      // errorOpenModal();
    }
  };

  const onSave = async () => {
    try {
      if (nick.length <= 1) {
        setIsErrorMessage(true);
        return;
      }

      if (accountInfo?.profile !== url) {
        await updateImage(url);
      }
      const result = await updateName(nick);
      const myInfo = await getMyInfo();
      setAccountInfo({
        address: myInfo.address,
        uid: myInfo.uid,
        nonceVerified: myInfo.nonceVerified,
        name: myInfo.name,
        profile: myInfo.profile,
        walletidx: myInfo.walletidx,
      });
      setIsErrorMessage(false);

      setEditMode('read');
    } catch (err) {
      alert('에러가 발생했습니다.');
    }
  };

  const onCopy = () => {
    copy(accountInfo?.address);
    setIsSuccessCopy(true);
    setTimeout(() => {
      setIsSuccessCopy(false);
    }, 3000);
  };

  const bootstrap = async () => {
    await getMyInfo().catch((err) => {
      navigate('/');
    });
  };

  useEffect(() => {
    bootstrap();
  }, []);

  useEffect(() => {
    setUrl(accountInfo?.profile);
  }, [accountInfo?.profile]);

  return (
    <>
      <article>
        <section className={classNames('mypage', 'inner-box', { 'nick-edit': editMode === 'edit' && !mobileCss })}>
          <div className="my-info">
            <figure
              className="pfp"
              onClick={handleImageClick}
              style={{ cursor: editMode === 'edit' ? 'pointer' : 'inherit' }}
            >
              <img src={url} alt="" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
            </figure>
            {editMode === 'read' ? (
              <div className="txt">
                <strong className="nickname">{accountInfo?.name} 님</strong>
                <div className="address">
                  <SpanWrapper>{accountInfo?.address}</SpanWrapper>
                  <button onClick={onCopy}>
                    <img src={img_btn_copy} alt="" />
                  </button>
                  {isSuccessCopy && <em className="copy-finish">복사 완료!</em>}
                </div>
                <div className="btn">
                  <a
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      setEditMode('edit');
                      setNick(accountInfo?.name);
                    }}
                    className="btn-type01 line"
                  >
                    편집하기
                  </a>
                  <a style={{ cursor: 'pointer' }} onClick={qrOpenModal} className="btn-type01">
                    홀더 인증하기
                  </a>
                </div>
              </div>
            ) : (
              <div className="txt edit">
                <span className="input">
                  <input
                    type="text"
                    placeholder=""
                    value={nick}
                    onChange={(e) => {
                      if (e.target.value.length === 21) {
                        return;
                      }

                      setNick(e.target.value);
                    }}
                    onKeyDown={async (e) => {
                      if (e.key === 'Enter') {
                        await onSave();
                        return;
                      }

                      if (e.key === 'Escape') {
                        setEditMode('read');
                        setIsErrorMessage(false);
                      }
                    }}
                  />
                  <button className="input-del" onClick={onResetNick}>
                    <img src={img_input_del} alt="" />
                  </button>
                </span>
                {isErrorMessage && <p className="notice">두글자이상 입력해주세요.</p>}
                <DivWrapper className="btn">
                  <a style={{ cursor: 'pointer' }} onClick={onSave} className="btn-type01 line">
                    완료
                  </a>
                  <a
                    className="btn-type01"
                    href="#"
                    onClick={() => {
                      setEditMode('read');
                      setIsErrorMessage(false);
                    }}
                  >
                    취소
                  </a>
                </DivWrapper>
              </div>
            )}
          </div>
          <ul className="mytab">
            <li className={classNames({ curr: membershipMatch !== null })}>
              <Link
                to="membership"
                style={{ cursor: 'pointer' }}

                // onClick={() => {
                //   if (!isProduction) {
                //     navigate('membership');
                //
                //     return;
                //   }
                //
                //   const lowerCaseWhiteLists = WHITE_LISTS.map((item) => {
                //     return item.toLowerCase();
                //   });
                //
                //   if (lowerCaseWhiteLists.includes(accountInfo?.address.toLowerCase())) {
                //     navigate('membership');
                //     return;
                //   }
                //
                //   openModal();
                // }}
              >
                나의 멤버십
              </Link>
            </li>
            <li className={classNames({ curr: metakingzMatch !== null })}>
              <Link to="metakingz">나의 메타킹즈</Link>
            </li>
          </ul>
          <Outlet />
        </section>
        <input
          type="file"
          accept="image/*"
          style={{ display: 'none' }}
          onChange={handleImageUpload}
          ref={inputOpenImageRef}
        />

        <ModalPortal>
          <InfoModal text="준비 중입니다." closeHandler={closeModal} />
        </ModalPortal>

        <QrModalPortal>
          <VerifyHolderModal closeModal={qrCloseModal} />
        </QrModalPortal>
      </article>
    </>
  );
};

export default MyInfoPage;
