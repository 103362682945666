// @ts-ignore
import { prepare, request, getResult, getCardList } from 'klip-sdk';
import React, { SetStateAction } from 'react';
import axios from 'axios';
import { APICore, setAuthorization } from '@api/index';
import { getMyInfo, signIn, verifyUser } from '@api/account';
import { SetterOrUpdater } from 'recoil';
import { KLIP, walletLoginKey } from '@utils/klip/connectKlipMobileWallet';
import { KLIP_WALLET_IDX } from '@components/Layouts/Header';
import myInfo from '@pages/MyInfo';

export const bappName = 'METAKINGZ';
export const successLink = '';
export const failLink = '';

interface Props {
  openModal: () => void;
  setLoginQr: React.Dispatch<SetStateAction<string>>;
  setAccountInfo: any;
  setIsLogin: any;
  closeModal: () => void;
  closeQrModal: () => void;
  setQrMessage: React.Dispatch<SetStateAction<string>>;
  setLoginWallet: SetterOrUpdater<'' | 'kaikas' | 'klip'>;
}

const api = new APICore();

export type VoidFn = () => void;

const klipMobileSign = async (
  address: string,
  setLoginQr: React.Dispatch<SetStateAction<string>>,
  setAccountInfo: any,
  setIsLogin: any,
  closeModal: VoidFn,
  closeQrModal: VoidFn,
  setQrMessage: React.Dispatch<SetStateAction<string>>,
  setLoginWallet: SetterOrUpdater<'' | 'kaikas' | 'klip'>
) => {
  try {
    const publicAddress = address.toLowerCase();
    const signInData = await signIn(publicAddress, KLIP_WALLET_IDX);

    const signMessage = signInData.sigmessage.split(':')[0] + ':';

    const body = {
      bappName,
      value: `${signMessage} ${signInData.nonce}`,
    };

    const res = await prepare.signMessage(body);
    if (res.err) {
      // 에러 처리
    } else if (res.request_key) {
      // request_key 보관
      setQrMessage('Klip Sign');

      setLoginQr(`https://klipwallet.com/?target=/a2a?request_key=${res.request_key}`);
      let timer = setInterval(() => {
        axios
          .get(`https://a2a-api.klipwallet.com/v2/a2a/result?request_key=${res.request_key}`)
          .then(async (response) => {
            if (response.data.status === 'canceled') {
              clearInterval(timer);
              return;
            }

            if (response.data.status === 'completed') {
              clearInterval(timer);
              // const body = { address: publicAddress, signature: response.data.result.signature, walletidx: 1 };
              // await verifyUser(body, signInData.token);

              setAuthorization(signInData.token);
              const myInfoData = await getMyInfo();

              api.setLoggedInUser(signInData.token);
              setAccountInfo({
                address: myInfoData.address,
                uid: myInfoData.uid,
                nonceVerified: myInfoData.nonceVerified,
                name: myInfoData.name,
                profile: myInfoData.profile,
                walletidx: myInfoData.walletidx,
              });
              setIsLogin(true);
              localStorage.setItem('isLogin', 'true');
              setLoginWallet('klip');
              localStorage.setItem(walletLoginKey, KLIP);
              closeModal();
              closeQrModal();
              clearInterval(timer);
            }
          })
          .catch((err) => {
            console.log('sign', err);
            clearInterval(timer);
          });
      }, 1000);
    }
  } catch (err) {}
};

export const pcMobileLogin = async (props: any) => {
  const { selectedAddress } = props;
  const publicAddress = selectedAddress.toLowerCase();
  const signInData = await signIn(publicAddress, KLIP_WALLET_IDX);
  const signMessage = signInData.sigmessage.split(':')[0] + ':';
  const body = {
    bappName,
    value: `${signMessage} ${signInData.nonce}`,
  };

  const signResponse = await prepare.signMessage(body);

  if (signResponse.err) {
    // 에러 처리
  } else if (signResponse.request_key) {
    props.setQrMessage('Klip Sign');

    props.setLoginQr(`https://klipwallet.com/?target=/a2a?request_key=${signResponse.request_key}`);

    let timer = setInterval(() => {
      axios
        .get(`https://a2a-api.klipwallet.com/v2/a2a/result?request_key=${signResponse.request_key}`)
        .then(async (response) => {
          if (response.data.status === 'canceled') {
            clearInterval(timer);
            return;
          }

          if (response.data.status === 'completed') {
            clearInterval(timer);
            const body = { address: publicAddress, signature: response.data.result.signature, walletidx: 1 };
            // await verifyUser(body, signInData.token);
            setAuthorization(signInData.token);
            const myInfoData = await getMyInfo();

            api.setLoggedInUser(signInData.token);
            props.setAccountInfo({
              address: myInfoData.address,
              uid: myInfoData.uid,
              nonceVerified: myInfoData.nonceVerified,
              name: myInfoData.name,
              profile: myInfoData.profile,
              walletidx: myInfoData.walletidx,
            });
            props.setIsLogin(true);
            localStorage.setItem('isLogin', 'true');
            props.setLoginWallet('klip');
            localStorage.setItem(walletLoginKey, KLIP);
            props.closeLoginModal();
            clearInterval(timer);
          }
        })
        .catch((err) => {
          console.log('sign', err);
          clearInterval(timer);
        });
    }, 1000);
  }
};

export const klipConnectWallet = async ({
  openModal,
  setLoginQr,
  setAccountInfo,
  setIsLogin,
  closeModal,
  closeQrModal,
  setQrMessage,
  setLoginWallet,
}: Props) => {
  const res = await prepare.auth({ bappName, successLink, failLink });

  if (res.err) {
  } else if (res.request_key) {
    openModal();
    setQrMessage('Klip 로그인');
    setLoginQr(`https://klipwallet.com/?target=/a2a?request_key=${res.request_key}`);

    let timer = setInterval(() => {
      axios
        .get(`https://a2a-api.klipwallet.com/v2/a2a/result?request_key=${res.request_key}`)
        .then(async (response) => {
          if (response.data.status === 'canceled') {
            clearInterval(timer);
            return;
          }
          if (response.data.status === 'completed') {
            clearInterval(timer);
            const selectedAddress = response.data.result.klaytn_address;

            if (selectedAddress) {
              const publicAddress = selectedAddress.toLowerCase();
              const signInData = await signIn(publicAddress, KLIP_WALLET_IDX);
              setAuthorization(signInData.token);
              const myInfoData = await getMyInfo();

              api.setLoggedInUser(signInData.token);
              setAccountInfo({
                address: myInfoData.address,
                uid: myInfoData.uid,
                nonceVerified: myInfoData.nonceVerified,
                name: myInfoData.name,
                profile: myInfoData.profile,
                walletidx: myInfoData.walletidx,
              });

              setIsLogin(true);
              localStorage.setItem('isLogin', 'true');
              setLoginWallet('klip');
              localStorage.setItem(walletLoginKey, KLIP);
              closeQrModal();
              closeModal();
              // await klipMobileSign(
              //   selectedAddress,
              //   setLoginQr,
              //   setAccountInfo,
              //   setIsLogin,
              //   closeModal,
              //   closeQrModal,
              //   setQrMessage,
              //   setLoginWallet
              // );
            }
          }
        })
        .catch((err) => {
          console.log('err', err);
          clearInterval(timer);
        });
    }, 1000);
  }
};

export default klipConnectWallet;
