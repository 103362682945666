import { bappName, failLink, successLink } from '@utils/klip/connectKlipWallet';
// @ts-ignore
import { prepare, request, getResult, getCardList } from 'klip-sdk';
import axios from 'axios';
import { getMyInfo, signIn, verifyUser } from '@api/account';
import { APICore, setAuthorization } from '@api/index';
import React, { SetStateAction } from 'react';
import { useNavigate } from 'react-router';
import myInfo from '@pages/MyInfo';
import { KLIP_WALLET_IDX } from '@components/Layouts/Header';
export const jsonStr = JSON.stringify;
const api = new APICore();
export const KLIP = 'klip';
export const KAIKAS = 'kaikas';
export const walletLoginKey = 'walletLoginKey';
interface SignProps extends Props {
  address: string;
}

const getKlipAccessUrl = (request_key: string) => {
  return `kakaotalk://klipwallet/open?url=https://klipwallet.com/?target=/a2a?request_key=${request_key}
`;
};

const klipMobileSign = async ({ address, setLoginWallet, setIsLogin, setAccountInfo }: SignProps) => {
  try {
    const publicAddress = address.toLowerCase();
    const signInData = await signIn(publicAddress, KLIP_WALLET_IDX);
    const signMessage = signInData.sigmessage.split(':')[0] + ':';
    const body = {
      bappName,
      value: `${signMessage} ${signInData.nonce}`,
    };

    const res = await prepare.signMessage(body);
    // alert(jsonStr(res));

    if (res.err) {
      // 에러 처리
    } else if (res.request_key) {
      window.location.href = getKlipAccessUrl(res.request_key);
      // await request(res.request_key, () => alert('mobile에서 사용하세요.'));
      let timerId = setInterval(async () => {
        const _result = await getResult(res.request_key);
        if (_result.status === 'canceled') {
          clearInterval(timerId);
          alert('취소되었습니다.');
          return;
        }

        if (_result.status === 'completed') {
          try {
            // alert('here');
            clearInterval(timerId);
            const body = { address: publicAddress, signature: _result.result.signature, walletidx: 1 };
            await verifyUser(body, signInData.token);
            setAuthorization(signInData.token);
            const myInfoData = await getMyInfo();
            if (JSON.parse(myInfoData.nonceVerified)) {
              api.setLoggedInUser(signInData.token);
              setAccountInfo({
                address: myInfoData.address,
                uid: myInfoData.uid,
                nonceVerified: myInfoData.nonceVerified,
                name: myInfoData.name,
                profile: myInfoData.profile,
                walletidx: myInfoData.walletidx,
              });
              setIsLogin(true);
              localStorage.setItem('isLogin', 'true');
              setLoginWallet('klip');
              localStorage.setItem(walletLoginKey, KLIP);
            }
          } catch (err) {
            alert(jsonStr(err));
          }
        }
      }, 3000);
    }
  } catch (err) {}
};

interface Props {
  setAccountInfo: any;
  setIsLogin: React.Dispatch<SetStateAction<boolean>>;
  setLoginWallet: React.Dispatch<SetStateAction<'' | 'klip' | 'kaikas'>>;
  setQrMessage: React.Dispatch<SetStateAction<string>>;
}

export const connectKlipMobileWallet = async ({ setAccountInfo, setIsLogin, setLoginWallet, setQrMessage }: Props) => {
  const res = await prepare.auth({ bappName, successLink, failLink });

  if (res.err) {
  } else if (res.request_key) {
    await request(res.request_key, () => {
      console.log('');
    });

    let timer = setInterval(async () => {
      const _result = await getResult(res.request_key);
      if (_result.status === 'canceled') {
        clearInterval(timer);
        alert('취소 되었습니다.');
        return;
      }
      if (_result.status === 'completed') {
        clearInterval(timer);

        // useNavigate()('/mint');

        // alert(jsonStr(_result));
        // ?

        if (_result.result.klaytn_address) {
          const publicAddress = _result.result.klaytn_address.toLowerCase();
          const signInData = await signIn(publicAddress, KLIP_WALLET_IDX);
          setAuthorization(signInData.token);
          const myInfoData = await getMyInfo();

          api.setLoggedInUser(signInData.token);
          setAccountInfo({
            address: myInfoData.address,
            uid: myInfoData.uid,
            nonceVerified: myInfoData.nonceVerified,
            name: myInfoData.name,
            profile: myInfoData.profile,
            walletidx: myInfoData.walletidx,
          });

          setIsLogin(true);
          localStorage.setItem('isLogin', 'true');
          setLoginWallet('klip');
          localStorage.setItem(walletLoginKey, KLIP);
          // await klipMobileSign({
          //   address: _result.result.klaytn_address,
          //   setAccountInfo,
          //   setLoginWallet,
          //   setIsLogin,
          //   setQrMessage,
          // });
        }
      }
    }, 1000);
  }
};
