import img_book from '@assets/images/ico_book.png';
import img_link from '@assets/images/ico_link2.png';
import img_globe from '@assets/images/ico_globe.png';
import { useState } from 'react';
import classNames from 'classnames';

export const iconLists = [
  {
    title: 'Telegram',
    url: 'https://t.me/METAKINGZCommunity',
    className: 'ico-tel',
  },
  {
    title: 'Instagram',
    url: 'https://www.instagram.com/metakingz_official/',
    className: 'ico-insta',
  },
  {
    title: 'Kakao',
    url: 'https://open.kakao.com/o/gC3eRFJe',
    className: 'ico-kakao',
  },
  {
    title: 'Discord',
    url: 'https://discord.com/invite/AdBS3NUgU9',
    className: 'ico-discord',
  },
];

const IConBox = () => {
  const [isLinkHover, setIsLinkHover] = useState(false);

  return (
    <div
      className='btn-box'
      onMouseLeave={() => {
        setIsLinkHover(false);
      }}
    >
      <div className='select-box'>
        <p
          className='button'
          onClick={() => {
            window.open(
              'https://secrettown.gitbook.io/metakingz2024',
              '_blank'
            );
          }}
        >
          {/* <img src={img_book} alt="" /> */}
          <p>사용자 가이드</p>
        </p>
      </div>
      <div className='select-box link'>
        <button
          className={classNames('down', { on: isLinkHover })}
          onMouseOver={() => {
            setIsLinkHover(true);
          }}
        >
          <img src={img_link} alt='' />
        </button>
        <div style={{ display: isLinkHover ? 'block' : 'none' }}>
          <ul>
            {iconLists.map((data, i) => (
              <li key={i} className={data.className}>
                <a href={data.url} target='_blank' rel='noreferrer'>
                  {data.title}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
      {/* <div className='select-box lang'>
        <button className='down'>KOR</button>
      </div> */}

      {/*<div className="select-box lang">*/}
      {/*  <button className="down">*/}
      {/*    <img src={img_globe} alt="" />*/}
      {/*  </button>*/}
      {/*  <div>*/}
      {/*    <ul>*/}
      {/*      <li>*/}
      {/*        <a href="#">KOR</a>*/}
      {/*      </li>*/}
      {/*      <li>*/}
      {/*        <a href="#">ENG</a>*/}
      {/*      </li>*/}
      {/*    </ul>*/}
      {/*  </div>*/}
      {/*</div>*/}
    </div>
  );
};

export default IConBox;
