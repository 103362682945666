import { FC } from 'react';
import { ModalProps } from '@components/Modal/Info';

const MintFailModal: FC<ModalProps> = ({ text, closeHandler }) => {
  return (
    <>
      <div className="popup-wrap" style={{ display: 'block' }}>
        <div className="dim" />
        <div className="pop-type01 pop-fail" style={{ display: 'block' }}>
          <p className="ico-fail desc">{text}</p>
          <a style={{ cursor: 'pointer' }} onClick={closeHandler} className="btn-type01 purple pop-close">
            확 인
          </a>
        </div>
      </div>
    </>
  );
};

export default MintFailModal;
