import { FC } from 'react';

const SuccessMintModal: FC<{ closeHandler: () => void }> = ({ closeHandler }) => {
  return (
    <>
      <div className="popup-wrap" style={{ display: 'block' }}>
        <div className="dim" />

        <div className="pop-type01 pop-success" style={{ display: 'block' }}>
          <strong className="pop-tit black ico-success">민팅 성공</strong>
          <p className="desc">민팅에 성공했습니다.</p>
          <a style={{ cursor: 'pointer ' }} onClick={closeHandler} className="btn-type01 purple pop-close">
            확 인
          </a>
        </div>
      </div>
    </>
  );
};

export default SuccessMintModal;
