import img_logo_big from '@assets/images/logo_big.png';
import ReactCountDown, { zeroPad } from 'react-countdown';
import moment from 'moment';
import momentTimeZone from 'moment-timezone';
import { Link } from 'react-router-dom';

const DDAY = '2022-12-05 13:00:00';

const DDayPage = () => {
  const krTime = momentTimeZone(DDAY).tz('Asia/Seoul').format('YYYY-MM-DD HH:mm:ss');
  const localTime = moment(DDAY).format('YYYY-MM-DD HH:mm:ss');
  const differ = moment(krTime).valueOf() - moment(localTime).valueOf();

  const renderer = ({
    days,
    hours,
    minutes,
    seconds,
    completed,
  }: {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
    completed: boolean;
  }) => {
    if (completed) {
      return <CompletedTime />;
    } else {
      // Render a countdown
      return (
        <>
          <div className="timer-count center">
            <div className="logo">
              <img src={img_logo_big} alt="" />
            </div>
            <ul className="count">
              <li>
                <span>{zeroPad(days)}</span>
                <em>Days</em>
              </li>
              <li>
                <span>{zeroPad(hours)}</span>
                <em>Hrs</em>
              </li>
              <li>
                <span>{zeroPad(minutes)}</span>
                <em>Mins</em>
              </li>
              <li>
                <span>{zeroPad(seconds)}</span>
                <em>Sec</em>
              </li>
            </ul>
          </div>
        </>
      );
    }
  };

  const CompletedTime = () => (
    <>
      <div className="mint-now center">
        <div className="logo">
          <img src={img_logo_big} alt="" />
        </div>
        <Link to="/mint" className="btn-type01">
          MINT NOW
        </Link>
      </div>
    </>
  );

  return (
    <>
      <article>
        <section className="timer">
          <ReactCountDown date={moment(localTime).valueOf() - differ} renderer={renderer}>
            <div />
          </ReactCountDown>
        </section>
      </article>
    </>
  );
};

export default DDayPage;
