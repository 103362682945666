import { FC } from 'react';
import { ModalProps } from '@components/Modal/Info';

interface Props {
  closeHandler: () => void;
}

const WhiteListsFailModal: FC<Props> = ({ closeHandler }) => {
  return (
    <>
      <div className="popup-wrap" style={{ display: 'block' }}>
        <div className="dim" />
        <div className="pop-type01 pop-fail" style={{ display: 'block' }}>
          <p className="ico-fail desc" style={{ fontWeight: 'bold' }}>
            앗! 화이트리스트 대상자가 아닙니다. <br />
            퍼블릭 민팅에 참여해주세요!
          </p>
          <a style={{ cursor: 'pointer' }} onClick={closeHandler} className="btn-type01 purple pop-close">
            확 인
          </a>
        </div>
      </div>
    </>
  );
};

export default WhiteListsFailModal;
