import { useEffect, useMemo, useState } from 'react';
import { BenefitLists, EventLists, getMtNft, getMyBenefit, getMyEventLists, getMyTier, MyTier } from '@api/app';
import WelcomeCard from '@components/WelcomeCard';
import GoldCard from '@components/GoldCard';
import PlatinumCard from '@components/PlatinumCard';
import BlackCard from '@components/BlackCard';
import { useRecoilState } from 'recoil';
import { accountInfoAtom, modeTabAtom } from '@recoil/atoms';
import BlankCard from '@components/BlankCard';
import { MyNftResult, WHITE_LISTS } from '@pages/MyInfo';
import styled from '@emotion/styled';
import img_blank from '@assets/images/img_blank-character.png';
import { useNavigate } from 'react-router';
import { isProduction } from '@api/index';
const getTier = (grade: number) => {
  switch (grade) {
    case 0:
      return 'welcome';

    case 1:
      return 'gold';

    case 2:
      return 'platinum';

    case 3:
      return 'black';

    default:
      return 'welcome';
  }
};

const DivContainer = styled.div`
  .lv-welcome .blank-txt {
    margin-top: 194px !important;
    text-align: center !important;
    width: 822px !important;
    strong {
      background: url(${img_blank}) 50% 0 no-repeat;
      color: #000;
      display: block;
      font-size: 24px;
      font-weight: 600;
      line-height: 2;
      padding-top: 300px;
    }
  }

  .lv-platinum .blank-txt {
    margin-top: 194px !important;
    text-align: center !important;
    width: 822px !important;
    strong {
      background: url(${img_blank}) 50% 0 no-repeat;
      color: #000;
      display: block;
      font-size: 24px;
      font-weight: 600;
      line-height: 2;
      padding-top: 300px;
    }
  }

  .lv-black .blank-txt {
    margin-top: 194px !important;
    text-align: center !important;
    width: 822px !important;
    strong {
      background: url(${img_blank}) 50% 0 no-repeat;
      color: #000;
      display: block;
      font-size: 24px;
      font-weight: 600;
      line-height: 2;
      padding-top: 300px;
    }
  }

  .lv-gold .blank-txt {
    margin-top: 194px !important;
    text-align: center !important;
    width: 822px !important;
    strong {
      background: url(${img_blank}) 50% 0 no-repeat;
      color: #000;
      display: block;
      font-size: 24px;
      font-weight: 600;
      line-height: 2;
      padding-top: 300px;
    }
  }

  @media (max-width: 1280px) {
    .lv-welcome .blank-txt {
      margin-top: 48px !important;
      width: auto !important;
      strong {
        background-size: auto 213px;
        font-size: 20px;
        line-height: 2.4;
        padding-top: 196px;
      }
    }

    .lv-platinum .blank-txt {
      margin-top: 48px !important;
      width: auto !important;
      strong {
        background-size: auto 213px;
        font-size: 20px;
        line-height: 2.4;
        padding-top: 196px;
      }
    }

    .lv-black .blank-txt {
      margin-top: 48px !important;
      width: auto !important;
      strong {
        background-size: auto 213px;
        font-size: 20px;
        line-height: 2.4;
        padding-top: 196px;
      }
    }

    .lv-gold .blank-txt {
      margin-top: 48px !important;
      width: auto !important;
      strong {
        background-size: auto 213px;
        font-size: 20px;
        line-height: 2.4;
        padding-top: 196px;
      }
    }
  }
`;

const Membership = () => {
  const [mode, setMode] = useRecoilState(modeTabAtom);
  // const [gradre, setTier] = useState<MyTier | null>(null);
  const [tier, setTier] = useState<'welcome' | 'gold' | 'platinum' | 'black' | null>(null);
  const [benefitLists, setBenefitLists] = useState<BenefitLists[]>([]);
  const [eventLists, setEventLists] = useState<EventLists[]>([]);
  const [isChcekNft, setIsCheckNft] = useState(false);
  const [accountInfo, setAccountInfo] = useRecoilState(accountInfoAtom);
  const [myNftInfo, setMyNftInfo] = useState<MyTier | null>(null);

  const navigate = useNavigate();

  const getMyInfo = async () => {
    const myTier = await getMyTier();
    setTier(getTier(myTier.userGrade));
    setMyNftInfo(myTier);

    const myNftsResult = await getMtNft();
    if (myNftsResult.length === 0) {
      setIsCheckNft(true);
    } else {
      setIsCheckNft(false);
    }
  };

  const bootstrap = async () => {
    if (mode !== 'event') {
      const _benefit = await getMyBenefit(mode);
      setBenefitLists(_benefit);
    }

    if (mode === 'event') {
      const _eventLists = await getMyEventLists();
      setEventLists(_eventLists);
    }
  };
  const Card = {
    welcome: (
      <WelcomeCard
        key={1}
        items={mode === 'event' ? eventLists : benefitLists}
        bootstrap={bootstrap}
        tier={tier}
        myNftInfo={myNftInfo}
      />
    ),
    gold: (
      <GoldCard
        key={2}
        items={mode === 'event' ? eventLists : benefitLists}
        bootstrap={bootstrap}
        tier={tier}
        myNftInfo={myNftInfo}
      />
    ),
    platinum: (
      <PlatinumCard
        key={3}
        items={mode === 'event' ? eventLists : benefitLists}
        bootstrap={bootstrap}
        tier={tier}
        myNftInfo={myNftInfo}
      />
    ),
    black: (
      <BlackCard
        key={4}
        items={mode === 'event' ? eventLists : benefitLists}
        bootstrap={bootstrap}
        tier={tier}
        myNftInfo={myNftInfo}
      />
    ),
  };

  const memoAccountInfo = useMemo(() => accountInfo, [accountInfo]);

  useEffect(() => {
    bootstrap();
  }, [mode, JSON.stringify(accountInfo)]);

  useEffect(() => {
    // const lowerCaseWhiteLists = WHITE_LISTS.map((item) => {
    //   return item.toLowerCase();
    // });
    // console.log(accountInfo?.address.toLowerCase(), lowerCaseWhiteLists);
    // if (
    //   isProduction &&
    //   accountInfo &&
    //   accountInfo.address &&
    //   !lowerCaseWhiteLists.includes(accountInfo?.address.toLowerCase())
    // ) {
    //   navigate('/profile/metakingz');
    // }

    getMyInfo();
  }, [JSON.stringify(accountInfo)]);

  return (
    <DivContainer className="my-benefits">
      {myNftInfo && myNftInfo.userGrade === 0 && myNftInfo.balance === 0 ? (
        <BlankCard items={mode === 'event' ? eventLists : benefitLists} bootstrap={bootstrap} tier={tier} />
      ) : (
        tier && Card[tier]
      )}
    </DivContainer>
  );
};

export default Membership;
