import { cursorPointerStyle } from '@utils/stlyes';
import { useState } from 'react';
import classNames from 'classnames';
import { useRecoilState } from 'recoil';
import { modeTabAtom } from '@recoil/atoms';

const ModeTab = () => {
  const [mode, setMode] = useRecoilState(modeTabAtom);

  return (
    <ul className="tab">
      <li
        className={classNames({ curr: mode === 'offline' })}
        onClick={() => {
          if (mode == 'offline') {
            return;
          }
          setMode('offline');
        }}
      >
        <a style={cursorPointerStyle}>오프라인 혜택</a>
      </li>
      <li
        className={classNames({ curr: mode === 'online' })}
        onClick={async () => {
          if (mode === 'online') {
            return;
          }
          setMode('online');
        }}
      >
        <a style={cursorPointerStyle}>온라인 혜택</a>
      </li>
      <li
        className={classNames({ curr: mode === 'event' })}
        onClick={() => {
          if (mode === 'event') {
            return;
          }

          setMode('event');
        }}
      >
        <a style={cursorPointerStyle}>이벤트</a>
      </li>
    </ul>
  );
};

export default ModeTab;
