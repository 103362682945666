import React, { FC, SetStateAction, useEffect, useState } from 'react';
import { EventQrProps } from '@components/WelcomeCard';
import { AddIndexPropsBaseQrInfo, DivContainer } from '@components/Modal/CouponQr';
import { cursorPointerStyle } from '@utils/stlyes';
import img_pop_close_btn from '@assets/images/ico_pop-close.png';
import QRCode from 'react-qr-code';
import { isMobile } from '@pages/Minting';

interface Props {
  closeModal: () => void;
  qrString: string;
  qrMessage: string;
}

const LoginQrModal: FC<Props> = ({ closeModal, qrString, qrMessage }) => {
  const [modalQrLists, setModalQrLists] = useState([]);
  const [currentQr, setCurrentQr] = useState<EventQrProps | null>(null);
  const [shareQr, setShareQr] = useState<AddIndexPropsBaseQrInfo | null>(null);
  const [title, setTitle] = useState('');
  const [qr, setQr] = useState('');

  useEffect(() => {
    setQr(qrString);
    setTitle(qrMessage);
  }, [qrString, qrMessage]);

  return (
    <DivContainer className="popup-wrap" style={{ display: 'block', overflow: 'auto' }}>
      <div className="dim" />

      <div className="pop-type01 pop-qr">
        <a
          style={cursorPointerStyle}
          className="pop-close"
          onClick={() => {
            closeModal();
            window.location.reload();
          }}
        >
          <img src={img_pop_close_btn} alt="" />
        </a>
        <strong className="pop-tit black">{title}</strong>
        <p className="desc">QR코드를 스캔하여 {title}을 해주세요.</p>

        <div className="qr" style={{ marginTop: '20px' }}>
          <div className="code" style={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
            {qr && <QRCode value={qr} size={isMobile() || window.innerWidth < 960 ? 160 : 160} />}
          </div>
        </div>
      </div>
    </DivContainer>
  );
};

export default LoginQrModal;
