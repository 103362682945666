import React, { FC } from 'react';
import img_welcome_list from '@assets/images/img_lv-list-welcome.png';
import img_gold_list from '@assets/images/img_lv-list-gold.png';
import img_plat_list from '@assets/images/img_lv-list-platinum.png';
import img_black_list from '@assets/images/img_lv-list-black.png';
import { getEventQrCoupon } from '@api/app';
import { cursorPointerStyle } from '@utils/stlyes';
import { DivWrapper, EventQrProps } from '@components/WelcomeCard';

interface Props {
  data: any;
  eventOpenModal: () => void;
  setEventQr: React.Dispatch<React.SetStateAction<EventQrProps | null>>;
  setInfoMessage: React.Dispatch<React.SetStateAction<string>>;
  infoOpenModal: () => void;
  tier: 'welcome' | 'gold' | 'platinum' | 'black' | null;
}

const getImageTier = (tier: 'welcome' | 'gold' | 'platinum' | 'black' | null) => {
  if (tier) {
    switch (tier) {
      case 'welcome':
        return img_welcome_list;

      case 'gold':
        return img_gold_list;

      case 'platinum':
        return img_plat_list;
      case 'black':
        return img_black_list;
    }
  }
};

const EventBenefit: FC<Props> = ({ data, eventOpenModal, setEventQr, setInfoMessage, infoOpenModal, tier }) => {
  return (
    <li>
      <figure>
        <img src={getImageTier(tier)} alt="" />
      </figure>

      <div className="txt">
        <strong>
          {data.eventName} {data.eventUserAvailabilityYn === 'Y' ? 1 : 0}/1
        </strong>
        <span>{data.eventDesc}</span>
        <em>
          {data.eventStartDate}~{data.eventEndDate}
        </em>
        {/*<a onClick={historyOpenModal}>사용 내역 확인하기</a>*/}
      </div>
      {data.eventUserAvailabilityYn === 'Y' ? (
        <div
          className="btn-use"
          onClick={async () => {
            try {
              const result = await getEventQrCoupon(data?.eventUid);
              setEventQr({ ...result, id: data?.eventUid });
              eventOpenModal();
            } catch (err) {
              setInfoMessage('QR 정보를 가져오지 못했습니다.');
              infoOpenModal();
            }

            // console.log(result);
          }}
        >
          <a style={cursorPointerStyle}>사용하기</a>
        </div>
      ) : (
        <DivWrapper className="btn-use">
          <a style={cursorPointerStyle}>사용완료</a>
        </DivWrapper>
      )}
    </li>
  );
};

export default EventBenefit;
