import img_cloud from '@assets/images/img_cloud.png';
import bg_community00_on from '@assets/images/bg_community00-on.jpg';
import bg_community01_on from '@assets/images/bg_community01-on.jpg';
import bg_community02_on from '@assets/images/bg_community02-on.jpg';
import bg_community03_on from '@assets/images/bg_community03-on.jpg';
import bg_community04_on from '@assets/images/bg_community04-on.jpg';
import bg_community05_on from '@assets/images/bg_community05-on.jpg';
import bg_community06_on from '@assets/images/bg_community06-on.jpg';
import btn_table_arrow from '@assets/images/btn_table-arrow.png';
import { useState } from 'react';
import classNames from 'classnames';
import { cursorPointerStyle } from '@utils/stlyes';

type TabType = 'black' | 'platinum' | 'gold';

const Membership = () => {
  const [tab, setTab] = useState<TabType>('black');
  const [visible, setVisible] = useState(false);
  return (
    <div className='membership' id='metaMembership'>
      <div className='inner'>
        {/*  <div className='img-cloud'>
          <img src={img_cloud} alt='' />
        </div> */}
        <div className='top text-c'>
          {/*  <h2 className='tit'>membership</h2> */}
          <strong>
            METAKINGZ 홀더들을 위한{' '}
            <i className='br'>더욱 강력해진 멤버십 혜택!</i>
          </strong>
          <p>
            온·오프라인 교류 촉진, 홀더간 단합 및 커뮤니티 강화를{' '}
            <i className='br'>위해 다양한 오프라인 공간을 마련했습니다</i>
          </p>
        </div>
        {/*  <div className="community">
                    <h3 className="sub-tit">
                        Our
                        <i className="br">
                            <em>Community</em>
                        </i>
                    </h3>
                    <ul>
                        <li className="height-full community00">
                            <div className="pic">
                                <img src={bg_community00_on} alt="" />
                            </div>
                            <div className="flex-mobile">
                                <div>
                                    <em>COCONUT BOX</em>
                                    <strong>코코넛 박스</strong>
                                    <span>[서울 홍대]</span>
                                </div>
                                <p>
                                    홀더들의
                                    <br />
                                    메인 커뮤니티 라운지.
                                    <br />
                                    서울 홍대.
                                    <br />
                                    도심 속 휴양지 컨셉의
                                    <br />
                                    하이브리드 전시관.
                                    <br />
                                    NFT 전시 및<br />
                                    홀더 파티 개최
                                </p>
                            </div>
                        </li>
                        <li className="community01">
                            <div className="pic">
                                <img src={bg_community01_on} alt="" />
                            </div>
                            <em>Balii BBQ</em>
                            <strong>발리BBQ</strong>
                            <span>[서울 연남]</span>
                        </li>
                        <li className="community02">
                            <div className="pic">
                                <img src={bg_community02_on} alt="" />
                            </div>
                            <em>BALIMOON</em>
                            <strong>발리문</strong>
                            <span>[서울 연남]</span>
                        </li>
                        <li className="community03">
                            <div className="pic">
                                <img src={bg_community03_on} alt="" />
                            </div>
                            <em>Palmfarm Bali</em>
                            <strong>팜팜발리</strong>
                            <span>[서울 연남]</span>
                        </li>
                        <li className="community04">
                            <div className="pic">
                                <img src={bg_community04_on} alt="" />
                            </div>
                            <em>Beach Club</em>
                            <strong>비치클럽</strong>
                            <span>[인천 장봉도]</span>
                        </li>
                        <li className="community05">
                            <div className="pic">
                                <img src={bg_community05_on} alt="" />
                            </div>
                            <em>Purple Box</em>
                            <strong>퍼플박스</strong>
                            <span>[전남 신안]</span>
                        </li>
                        <li className="community06">
                            <div className="pic">
                                <img src={bg_community06_on} alt="" />
                            </div>
                            <em>COLORPOOL</em>
                            <strong>컬러풀뮤지엄</strong>
                            <span>[서울 인사동]</span>
                        </li>
                    </ul>
                </div> */}
        <div className='utility'>
          <h3 className='sub-tit'>
            Our<i className='br'> Utility</i>
          </h3>
          <div className='table-wrap pc'>
            <table>
              {/* <caption>
                ※ 멤버쉽 강화와 멤버 간 만남, 교류의 장을 활성화하기 위한 혜택
                제공
              </caption> */}
              <colgroup>
                <col width='195px' />
                <col width='*' />
                <col width='*' />
                <col width='*' />
              </colgroup>
              <thead>
                <tr>
                  <th colSpan={2}>등급</th>
                  <th>BLACK</th>
                  <th>PLATINUM</th>
                  <th>GOLD</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th colSpan={2}>보유수량</th>
                  <td className='bold'>5</td>
                  <td className='bold'>3</td>
                  <td className='bold'>1</td>
                </tr>
                <tr>
                  <th /* rowSpan={2} */ className='border-right'>F&B</th>
                  <th>코코넛박스</th>

                  <td>
                    올데이브런치
                    <br />
                    3만원권 2장
                  </td>
                  <td>
                    올데이브런치
                    <br />
                    3만원권 1장
                  </td>
                  <td>
                    아메리카노 (HOT)
                    <br />
                    2잔
                  </td>
                </tr>
                {/*  <tr>
                  <th>코코발리BBQ</th>
                  <td colSpan={3}>
                    식음료 10<span className='gap'>%</span> 할인
                  </td>
                </tr> */}
                <tr>
                  <th rowSpan={5} className='border-right'>
                    전시
                  </th>
                  <th>
                    컬러풀뮤지엄 <i className='br'>(인사동)</i>
                  </th>
                  <td colSpan={3}>전시관람권 2매(1인 1매 사용)</td>
                </tr>
                <tr>
                  <th>
                    퍼플박스<i className='br'>(전남 신안)</i>
                  </th>
                  <td colSpan={3}>전시관람권 2매(1인 1매 사용)</td>
                </tr>
                <tr>
                  <th>
                    청춘뮤지엄<i className='br'>(양평)</i>
                  </th>
                  <td colSpan={3}>전시관람권 2매(1인 1매 사용)</td>
                </tr>
                <tr>
                  <th>
                    그리스신화박물관<i className='br'>(제주)</i>
                  </th>
                  <td colSpan={3}>전시관람권 2매(1인 1매 사용)</td>
                </tr>
                <tr>
                  <th>
                    트릭아이뮤지엄<i className='br'>(제주)</i>
                  </th>
                  <td colSpan={3}>전시관람권 2매(1인 1매 사용)</td>
                </tr>

                <tr>
                  <th className='border-right'>숙박</th>
                  <th>
                    퍼플스테이<i className='br'>(전남 신안)</i>
                  </th>
                  <td colSpan={3}>
                    퍼플 VIP PACK 제공
                    <br />
                    (퍼플 스테이 숙박권 30%할인 + 퍼플섬 무료입장권 + 퍼플박스
                    미디어아트 무료입장)
                  </td>
                </tr>

                <tr>
                  <th colSpan={2} className='border-right border-bottom'>
                    이벤트 및 커뮤니티
                  </th>

                  <td colSpan={3} className='border-bottom'>
                    운영사 및 파트너사의 이벤트 및 각종 문화 행사 무료 참여 기회
                    제공
                  </td>
                </tr>
              </tbody>
            </table>
            <div
              className='hidden-cont'
              /*  style={{ display: visible ? 'block' : 'none' }} */
            >
              {/* <table className='table-benefit'>
                <caption>
                  <span className='ico-benefit'>메타킹즈 공통 혜택</span>
                </caption>
                <colgroup>
                  <col width='195px' />
                  <col width='*' />
                </colgroup>
                <tr>
                  <th>커뮤니티</th>
                  <td>
                    <div>
                      1) 네트워킹 파티
                      <br />
                      · 분기별 테마파티 개최 (발렌타인 데이, 썸머풀 파티, 할로윈
                      파티, 크리스마스)
                      <br />
                      · 홀더 100명 이상 요청 시 수시 깜짝파티 진행
                      <br />· 홀더 프라이빗 파티 요청 시 대관 지원금 제공
                    </div>
                    <div>
                      2) 홀더 전용 클럽 라운지 in 코코넛박스
                      <br />
                      · 코코넛박스 내 홀더 전용 공간 마련
                      <br />· 홀더에게 코코넛박스 연간 회원권 및 웰컴 드링크
                      제공
                    </div>
                    <div>
                      3) 비치클럽
                      <br />· 서울 근교 프라이빗 비치클럽 운영 (2023.07 오픈)
                    </div>
                    <div>
                      4) 버추얼 스페이스 서비스
                      <br />· 버추얼 커뮤니티 공간 제공 : 가상 NFT 전시 및
                      온라인 커뮤니티 활동 플랫폼
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>F&B</th>
                  <td>
                    · (서울 홍대) 코코넛박스 20% 할인
                    <br />· (서울 홍대) 코코발리BBQ 식음료 10% 할인
                    <br />· (서울 홍대) 코코발리BBQ '익스클루시브 라운지' 인원
                    상관 없이 입장 가능
                    <br />· (서울 연남) 발리문 음료 서비스
                    <br />· (서울 연남) 팜팜발리 메뉴 서비스
                    <br />· (강원 속초) 몽트비어 샘플러 1잔
                  </td>
                </tr>
                <tr>
                  <th>숙박</th>
                  <td>
                    (전남 신안) 퍼플 VIP pack 제공 (퍼플 스테이 숙박권 50% 할인
                    + 퍼플섬 무료 입장권 + 퍼플박스 미디어아트 무료 입장권)
                  </td>
                </tr>
                <tr>
                  <th>굿즈</th>
                  <td>
                    온·오프라인 굿즈 상품 20% 할인 (커뮤니티에서 인기 있는
                    아이디어로 AR실물카드, 에코백, 아트토이, 티셔츠, 후드티,
                    문구류 등 다양한 상품을 디자인하여 온·오프라인에서 판매
                    예정)
                  </td>
                </tr>
              </table> */}
              <ul className='notice'>
                <li className='color-txt'>
                  * 홀더 등급은 1년에 2회 (1월, 7월 첫번째 주 목요일) 스냅샷
                  데이터를 통해 갱신됩니다.
                </li>
                <li>
                  * 코코넛박스 올데이브런치 금액권 및 아메리카노(HOT)은 당일
                  사용 혜택입니다.
                  <i className='br' /> &nbsp; 차액 발생 시 남은 금액은 다른
                  날짜에 사용 불가하며, 차액에 대한 환불은 불가합니다. (제공
                  혜택 이상의 금액 발생 시 추가금 현장 결제)
                </li>
                <li>
                  * 제공되는 혜택은 다음 등급 갱신 시기인 6개월 이후 효력 소멸
                  됩니다.
                </li>
                <li>
                  * 시설측 사정으로 운영이 종료되거나 내부 사정으로 등급별
                  혜택은 임의변경 될 수 있습니다.
                </li>
              </ul>
            </div>
            {/* <div
              className={classNames('btn-arrow', {
                hidden: !visible ? true : null,
              })}
              onClick={() => {
                if (visible) {
                  window.scrollBy(0, -1500);
                }

                setVisible((prevState) => !prevState);
              }}
            >
              <a style={cursorPointerStyle}>
                <img src={btn_table_arrow} alt='' />
              </a>
            </div> */}
          </div>
          <div className='table-wrap mobile'>
            {/*  <strong className='caption'>
              ※ 멤버쉽 강화와 멤버 간 만남, 교류의 장을 활성화하기 위한 혜택
              제공
            </strong> */}
            <ul className='table-tab'>
              <li
                className={classNames({ curr: tab === 'black' })}
                onClick={() => {
                  setTab('black');
                }}
              >
                <a style={cursorPointerStyle}>BLACK</a>
              </li>
              <li
                className={classNames({ curr: tab === 'platinum' })}
                onClick={() => {
                  setTab('platinum');
                }}
              >
                <a style={cursorPointerStyle}>PLATINUM</a>
              </li>
              <li
                className={classNames({ curr: tab === 'gold' })}
                onClick={() => {
                  setTab('gold');
                }}
              >
                <a style={cursorPointerStyle}>GOLD</a>
              </li>
            </ul>
            <div className='table-cont-box'>
              <div
                className={classNames('cont black', { on: tab === 'black' })}
              >
                <table>
                  <colgroup>
                    <col width='45px' />
                    <col width='120px' />
                  </colgroup>
                  <thead>
                    <tr>
                      <th colSpan={2}>등급</th>
                      <th className='text-c'>BLACK</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th colSpan={2}>보유수량</th>
                      <td className='bold'>5</td>
                    </tr>
                    <tr>
                      <th /* rowSpan={2} */ className='border-right'>F&B</th>
                      <th>코코넛박스</th>
                      <td>
                        <p>
                          올데이브런치
                          <br />
                          3만원권 2장
                        </p>
                      </td>
                    </tr>
                    {/* <tr>
                      <th>코코발리BBQ</th>
                      <td>
                        식음료 10<span className='gap'>%</span> 할인
                      </td>
                    </tr> */}
                    <tr>
                      <th rowSpan={5} className='border-right'>
                        전시
                      </th>
                      <th>
                        컬러풀뮤지엄 <i className='br'>(인사동)</i>
                      </th>
                      <td>전시관람권 2매(1인 1매 사용)</td>
                    </tr>
                    <tr>
                      <th>
                        퍼플박스<i className='br'>(전남 신안)</i>
                      </th>
                      <td>전시관람권 2매(1인 1매 사용)</td>
                    </tr>
                    <tr>
                      <th>
                        청춘뮤지엄<i className='br'>(양평)</i>
                      </th>
                      <td>전시관람권 2매(1인 1매 사용)</td>
                    </tr>
                    <tr>
                      <th>
                        그리스신화박물관<i className='br'>(제주)</i>
                      </th>
                      <td>전시관람권 2매(1인 1매 사용)</td>
                    </tr>
                    <tr>
                      <th>
                        트릭아이뮤지엄<i className='br'>(제주)</i>
                      </th>
                      <td>전시관람권 2매(1인 1매 사용)</td>
                    </tr>

                    <tr>
                      <th className='border-right'>숙박</th>
                      <th>
                        퍼플스테이<i className='br'>(전남 신안)</i>
                      </th>
                      <td>
                        퍼플 VIP PACK 제공
                        <br />
                        (퍼플 스테이 숙박권 30%할인 + 퍼플섬 무료입장권 +
                        퍼플박스 미디어아트 무료입장)
                      </td>
                    </tr>

                    <tr>
                      <th colSpan={2} className='border-right border-bottom'>
                        이벤트 및 커뮤니티
                      </th>

                      <td className='border-bottom'>
                        운영사 및 파트너사의 이벤트 및 각종 문화 행사 무료 참여
                        기회 제공
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div
                className={classNames('cont platinum', {
                  on: tab === 'platinum',
                })}
              >
                <table>
                  <colgroup>
                    <col width='45px' />
                    <col width='120px' />
                  </colgroup>
                  <thead>
                    <tr>
                      <th colSpan={2}>등급</th>
                      <th className='text-c'>PLATINUM</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th colSpan={2}>보유수량</th>
                      <td className='bold'>3</td>
                    </tr>
                    <tr>
                      <th /* rowSpan={2}  */ className='border-right'>F&B</th>
                      <th>코코넛박스</th>
                      <td>
                        <p>
                          올데이브런치
                          <br />
                          3만원권 1장
                        </p>
                      </td>
                    </tr>
                    {/* <tr>
                      <th>코코발리BBQ</th>
                      <td>
                        식음료 10<span className='gap'>%</span> 할인
                      </td>
                    </tr> */}
                    <tr>
                      <th rowSpan={5} className='border-right'>
                        전시
                      </th>
                      <th>
                        컬러풀뮤지엄 <i className='br'>(인사동)</i>
                      </th>
                      <td>전시관람권 2매(1인 1매 사용)</td>
                    </tr>
                    <tr>
                      <th>
                        퍼플박스<i className='br'>(전남 신안)</i>
                      </th>
                      <td>전시관람권 2매(1인 1매 사용)</td>
                    </tr>
                    <tr>
                      <th>
                        청춘뮤지엄<i className='br'>(양평)</i>
                      </th>
                      <td>전시관람권 2매(1인 1매 사용)</td>
                    </tr>
                    <tr>
                      <th>
                        그리스신화박물관<i className='br'>(제주)</i>
                      </th>
                      <td>전시관람권 2매(1인 1매 사용)</td>
                    </tr>
                    <tr>
                      <th>
                        트릭아이뮤지엄<i className='br'>(제주)</i>
                      </th>
                      <td>전시관람권 2매(1인 1매 사용)</td>
                    </tr>

                    <tr>
                      <th className='border-right'>숙박</th>
                      <th>
                        퍼플스테이<i className='br'>(전남 신안)</i>
                      </th>
                      <td>
                        퍼플 VIP PACK 제공
                        <br />
                        (퍼플 스테이 숙박권 30%할인 + 퍼플섬 무료입장권 +
                        퍼플박스 미디어아트 무료입장)
                      </td>
                    </tr>

                    <tr>
                      <th colSpan={2} className='border-right border-bottom'>
                        이벤트 및 커뮤니티
                      </th>

                      <td className='border-bottom'>
                        운영사 및 파트너사의 이벤트 및 각종 문화 행사 무료 참여
                        기회 제공
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className={classNames('cont gold', { on: tab === 'gold' })}>
                <table>
                  <colgroup>
                    <col width='45px' />
                    <col width='120px' />
                  </colgroup>
                  <thead>
                    <tr>
                      <th colSpan={2}>등급</th>
                      <th className='text-c'>GOLD</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th colSpan={2}>보유수량</th>
                      <td className='bold'>1</td>
                    </tr>
                    <tr>
                      <th /* rowSpan={2} */ className='border-right'>F&B</th>
                      <th>코코넛박스</th>
                      <td>
                        <p>
                          아메리카노 (HOT)
                          <br />
                          2잔
                        </p>
                      </td>
                    </tr>
                    {/* <tr>
                      <th>코코발리BBQ</th>
                      <td>
                        식음료 10<span className='gap'>%</span> 할인
                      </td>
                    </tr> */}
                    <tr>
                      <th rowSpan={5} className='border-right'>
                        전시
                      </th>
                      <th>
                        컬러풀뮤지엄 <i className='br'>(인사동)</i>
                      </th>
                      <td>전시관람권 2매(1인 1매 사용)</td>
                    </tr>
                    <tr>
                      <th>
                        퍼플박스<i className='br'>(전남 신안)</i>
                      </th>
                      <td>전시관람권 2매(1인 1매 사용)</td>
                    </tr>
                    <tr>
                      <th>
                        청춘뮤지엄<i className='br'>(양평)</i>
                      </th>
                      <td>전시관람권 2매(1인 1매 사용)</td>
                    </tr>
                    <tr>
                      <th>
                        그리스신화박물관<i className='br'>(제주)</i>
                      </th>
                      <td>전시관람권 2매(1인 1매 사용)</td>
                    </tr>
                    <tr>
                      <th>
                        트릭아이뮤지엄<i className='br'>(제주)</i>
                      </th>
                      <td>전시관람권 2매(1인 1매 사용)</td>
                    </tr>

                    <tr>
                      <th className='border-right'>숙박</th>
                      <th>
                        퍼플스테이<i className='br'>(전남 신안)</i>
                      </th>
                      <td>
                        퍼플 VIP PACK 제공
                        <br />
                        (퍼플 스테이 숙박권 30%할인 + 퍼플섬 무료입장권 +
                        퍼플박스 미디어아트 무료입장)
                      </td>
                    </tr>

                    <tr>
                      <th colSpan={2} className='border-right border-bottom'>
                        이벤트 및 커뮤니티
                      </th>

                      <td className='border-bottom'>
                        운영사 및 파트너사의 이벤트 및 각종 문화 행사 무료 참여
                        기회 제공
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div
              className='hidden-cont'
              /*        style={{ display: visible ? 'block' : 'none' }} */
            >
              <ul className='notice'>
                <li className='color-txt'>
                  * 홀더 등급은 1년에 2회 (1월, 7월 첫번째 주 목요일) 스냅샷
                  데이터를 통해 갱신됩니다.
                </li>
                <li>
                  * 코코넛박스 올데이브런치 금액권 및 아메리카노(HOT)은 당일
                  사용 혜택입니다.
                  <i className='br' /> &nbsp; 차액 발생 시 남은 금액은 다른
                  날짜에 사용 불가하며, 차액에 대한 환불은 불가합니다. (제공
                  혜택 이상의 금액 발생 시 추가금 현장 결제)
                </li>
                <li>
                  * 제공되는 혜택은 다음 등급 갱신 시기인 6개월 이후 효력 소멸
                  됩니다.
                </li>
                <li>
                  * 시설측 사정으로 운영이 종료되거나 내부 사정으로 등급별
                  혜택은 임의변경 될 수 있습니다.
                </li>
              </ul>
            </div>
            {/* <div
              className={classNames('btn-arrow', {
                hidden: !visible ? 'hidden' : null,
              })}
              onClick={() => {
                if (visible) {
                  window.scrollBy(0, -1000);
                }

                setVisible((prevState) => !prevState);
              }}
            >
              <a style={cursorPointerStyle}>
                <img src={btn_table_arrow} alt='' />
              </a>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Membership;
