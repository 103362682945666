import img_book from '@assets/images/ico_book.png';
import img_wallet_c from '@assets/images/ico_wallet_txt.png';
import { FC } from 'react';
import { cursorPointerStyle } from '@utils/stlyes';
import IConBox from '@components/IConBox';

interface Props {
  openMenu: () => void;
}

const MobileIConBox: FC<Props> = ({ openMenu }) => {
  return (
    <>
      {/*  <div className="mobile icon">
        <span
          onClick={() => {
            window.open('https://secrettown.gitbook.io/metakingzguide/', '_blank');
          }}
        >
          <a style={cursorPointerStyle}>
            <img src={img_book} alt="" />
          </a>
        </span>
        <span className="ico-wallet">
          <a
            style={{ cursor: 'pointer' }}
            onClick={() => {
              openMenu();
            }}
          >
            <img src={img_wallet_c} alt="" />
          </a>
        </span>
      </div> */}

      <div className='mobile icon'>
        <span className='ico-wallet'>
          <a
            style={{ cursor: 'pointer' }}
            onClick={() => {
              openMenu();
            }}
          >
            <img src={img_wallet_c} alt='지갑연결' />
            {/* <span className='text'>지갑연결</span> */}
          </a>
        </span>

        {/* <span
          onClick={() => {
            window.open(
              'https://secrettown.gitbook.io/metakingzguide/',
              '_blank'
            );
          }}
        >
          사용자 가이드
          <a style={cursorPointerStyle}>
            <img src={img_book} alt='' />
          </a>
        </span> */}

        <IConBox />
      </div>
    </>
  );
};

export default MobileIConBox;
