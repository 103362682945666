import React, { useEffect, useLayoutEffect, useState } from 'react';
import logo from './logo.svg';
import './App.css';
import PublicRouter from '@routes/index';
import { useLocation } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { accountInfoAtom, finishAtom, loginWalletAtom } from '@recoil/atoms';
import { APICore, setAuthorization } from '@api/index';
import { getMyInfo } from '@api/account';
import { getNftProjectLists } from '@api/app';
import Header from '@components/Layouts/Header';
import Footer from '@components/Layouts/Footer';
import classNames from 'classnames';
import { walletLoginKey } from '@utils/klip/connectKlipMobileWallet';

function App() {
  const [accountInfo, setAccountInfo] = useRecoilState(accountInfoAtom);
  const [finish, setFinish] = useRecoilState(finishAtom);
  const [isLogin, setIsLogin] = useState(false);
  const location = useLocation();
  const [loginWallet, setLoginWallet] = useRecoilState(loginWalletAtom);

  const bootstrap = async () => {
    try {
      const myInfo = await getMyInfo();

      setAccountInfo({
        address: myInfo.address,
        uid: myInfo.uid,
        nonceVerified: myInfo.nonceVerified,
        name: myInfo.name,
        profile: myInfo.profile,
        walletidx: myInfo.walletidx,
      });
      setIsLogin(true);
    } catch (err) {
      setAuthorization('');
      setIsLogin(false);
      setLoginWallet('');
      setAccountInfo({
        address: '',
        uid: '',
        nonceVerified: '',
        name: '',
        profile: '',
        walletidx: -1,
      });
    }
  };
  const getFinishTime = async () => {
    const data = await getNftProjectLists();
    setFinish(data.finish);
  };
  const getKaikasAccount = async () => {
    const api = new APICore();
    const address = await window.klaytn.selectedAddress;
    const isLogin = localStorage.getItem('isLogin');
    const walletType = localStorage.getItem(walletLoginKey);
    if (JSON.parse(isLogin!) && walletType === 'kaikas' && !address) {
      localStorage.removeItem('isLogin');
      api.setLoggedInUser(null);
      window.location.reload();
    }
  };

  useLayoutEffect(() => {
    getFinishTime();
  }, []);

  useEffect(() => {
    bootstrap();
  }, []);

  useEffect(() => {
    getKaikasAccount();
  }, [location.pathname]);

  const isHome = location.pathname === '/';

  return (
    <>
      <main>
        <div id="contents" className={classNames({ 'landing-zoom': isHome })}>
          <Header />
          <PublicRouter />

          <Footer />
        </div>
      </main>
      <div id="modal" />
    </>
  );
}

export default App;
