import { APICore } from './index';
import axios from 'axios';

const api = new APICore();

export interface SignInReqBody {
  address: string;
  name: string;
  nonceVerified: string;
  profile: string;
  sigmessage: string;
  uid: string;
  nonce: string;
  token: string;
  walletidx: number;
}

export interface VerifyResBody {
  address: string;
  signature: string;
}

export interface MyInfo {
  uid: string;
  name: string;
  address: string;
  nonceVerified: string;
  profile: string;
  walletidx: number;
}

export const signIn = async (address: string, walletidx: number): Promise<SignInReqBody> => {
  return api.create('/account/signin', { address, walletidx }).then((resp) => resp.data.data);
};

export const verifyUser = async (body: VerifyResBody, token: string) => {
  return axios
    .post(`/account/nonce/verify`, body, {
      headers: {
        Authentication: token,
      },
    })
    .then((resp) => resp.data.data);
};

export const getMyInfo = async (): Promise<MyInfo> => {
  return api.get('/account/authenticated').then((resp) => resp.data.data);
};

export const updateName = async (name: string) => {
  return api.create(`/account/name`, { name }).then((resp) => resp.data);
};

export const uploadImage = async (body: FormData) => {
  return api.create(`/media/files?type=media`, body).then((resp) => resp.data.data);
};

export const updateImage = async (body: string) => {
  return api.create(`/account/profile`, { url: body }).then((resp) => resp.data);
};
