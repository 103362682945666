import img_black from '@assets/images/img_lv-black.png';
import img_black_list from '@assets/images/img_lv-list-black.png';
import { cursorPointerStyle } from '@utils/stlyes';
import ModeTab from '@components/ModeTab';
import { FC, useState } from 'react';
import {
  BaseBenefitsItems,
  BenefitProps,
  countEnableCoupon,
  DivContainer,
  DivWrapper,
  EventQrProps,
  getEnableCoupon,
} from '@components/WelcomeCard';
import { useRecoilValue } from 'recoil';
import { modeTabAtom } from '@recoil/atoms';
import img_plat_list from '@assets/images/img_lv-list-platinum.png';
import useModal from '@hooks/useModal';
import CouponQrModal, { AddIndexPropsBaseQrInfo } from '@components/Modal/CouponQr';
import img_welcome_list from '@assets/images/img_lv-list-welcome.png';
import { getEventQrCoupon, getQrCoupon } from '@api/app';
import HistoryModal from '@components/Modal/History';
import EventQrModal from '@components/Modal/EventQr';
import InfoModal from '@components/Modal/Info';
import RegularBenefit from '@components/RegularBenefits';
import EventBenefit from '@components/EventBenefit';
import { EmptyEventBenefit, EmptyOfflineBenefit, EmptyOnlineBenefit } from '@components/BlankCard';

function BlackCard(props: BaseBenefitsItems) {
  const mode = useRecoilValue(modeTabAtom);

  const [sn, setSn] = useState('');
  const [eventQr, setEventQr] = useState<EventQrProps | null>(null);
  const [couponType, setCouponType] = useState<number | null>(null);
  const { ModalPortal, openModal, closeModal } = useModal();
  const { ModalPortal: HistoryPortal, openModal: historyOpenModal, closeModal: historyCloseModal } = useModal();
  const [qrLists, setQrLists] = useState<AddIndexPropsBaseQrInfo[]>([]);
  const { ModalPortal: EventModal, openModal: eventOpenModal, closeModal: eventCloseModal } = useModal();
  const { ModalPortal: InfoModalPortal, openModal: infoOpenModal, closeModal: infoCloseModal } = useModal();
  const [infoMessage, setInfoMessage] = useState('');
  const [selectedBenefit, setSelectedBenefit] = useState(null);

  const emptyItems = () => {
    switch (mode) {
      case 'online':
        return EmptyOnlineBenefit();

      case 'offline':
        return EmptyOfflineBenefit();

      case 'event':
        return EmptyEventBenefit();
    }
  };
  return (
    <DivContainer className="lv-black">
      <div className="lv-card">
        <strong className="lv-tit">BLACK 등급</strong>
        <span className="img-lv">
          <img src={img_black} alt="" />
        </span>
        {/*<a style={cursorPointerStyle} className="btn-more">*/}
        {/*  더보기*/}
        {/*</a>*/}
        <div className="desc more-desc">
          {/*<strong>상시 사용 가능 혜택</strong>*/}
          <RegularBenefit tier={props.tier} myNftInfo={props.myNftInfo} />
        </div>
        {/*<div className="desc">*/}
        {/*  <strong>등급별 차등 혜택</strong>*/}
        {/*  <ul>*/}
        {/*    <li>· 코코넛박스 전시관람권 + 방갈로 1매</li>*/}
        {/*    <li>· 전시관 5곳 무료 입장권(각 2매 제공)</li>*/}
        {/*    <li>· NFT 아티스트 한정판 NFT 추첨 참여</li>*/}
        {/*  </ul>*/}
        {/*</div>*/}
      </div>
      <div className="right-cont">
        <ModeTab />
        <div className="cont-box">
          <div className="cont on">
            <ul className="benefit-list">
              {props?.items?.length === 0
                ? emptyItems()
                : mode !== 'event'
                ? props.items &&
                  props.items.map((data: any, index: number) => {
                    return (
                      <li key={index}>
                        <figure>
                          <img src={img_black_list} alt="" />
                        </figure>

                        <div className="txt">
                          <strong>
                            {data.couponTypeName}&nbsp;
                            {data.couponList.length === 0 ? 0 : countEnableCoupon(data.couponList)}/
                            {data.couponList.length}
                          </strong>
                          {/*<span>{data.couponTypeDesc}</span>*/}
                          <em>{data.couponDate}</em>
                          <a
                            onClick={() => {
                              setCouponType(data.couponType);
                              historyOpenModal();
                              setSelectedBenefit(data);
                            }}
                            style={cursorPointerStyle}
                          >
                            사용 내역 확인하기
                          </a>
                        </div>
                        {data?.couponList.length === 0 && countEnableCoupon(data.couponList) === 0 ? (
                          <DivWrapper className="btn-use">
                            <a style={cursorPointerStyle}>사용불가</a>
                          </DivWrapper>
                        ) : countEnableCoupon(data.couponList) === 0 && data?.couponList.length > 0 ? (
                          <DivWrapper className="btn-use">
                            <a style={cursorPointerStyle}>사용완료</a>
                          </DivWrapper>
                        ) : (
                          <div
                            className="btn-use"
                            onClick={async () => {
                              const result = getEnableCoupon(data?.couponList);
                              const _qrLists: any[] = [];
                              let index = 1;
                              for (let item of result) {
                                await getQrCoupon(item.userCouponSn)
                                  .then((resp) => {
                                    _qrLists.push({ ...resp, id: index, sn: item.userCouponSn });
                                    index++;
                                  })
                                  .catch((err) => {
                                    console.log(err);
                                  });
                              }

                              if (!_qrLists.length) {
                                setInfoMessage('QR 정보가 없습니다. 잠시후 다시 시도해주세요.');
                                infoOpenModal();
                              }

                              if (_qrLists.length) {
                                setQrLists(_qrLists);
                                openModal();
                              }
                            }}
                          >
                            <a style={cursorPointerStyle}>사용하기</a>
                          </div>
                        )}
                      </li>
                    );
                  })
                : props.items &&
                  props.items.map((data: any, index: number) => {
                    return (
                      <EventBenefit
                        key={index}
                        data={data}
                        eventOpenModal={eventOpenModal}
                        setEventQr={setEventQr}
                        setInfoMessage={setInfoMessage}
                        infoOpenModal={infoOpenModal}
                        tier={props.tier}
                      />
                      // <li key={index}>
                      //   <figure>
                      //     <img src={img_black_list} alt="" />
                      //   </figure>
                      //
                      //   <div className="txt">
                      //     <strong>
                      //       {data.eventName} {data.eventName} {data.eventUserAvailabilityYn !== 'Y' ? 0 : 1}/1
                      //     </strong>
                      //     <span>{data.eventDesc}</span>
                      //     <em>
                      //       {data.eventStartDate}~{data.eventEndDate}
                      //     </em>
                      //     {/*<a onClick={historyOpenModal}>사용 내역 확인하기</a>*/}
                      //   </div>
                      //   {data.eventUserAvailabilityYn !== 'Y' ? (
                      //     <div
                      //       className="btn-use"
                      //       onClick={async () => {
                      //         const result = await getEventQrCoupon(data?.eventUid);
                      //         setEventQr({ ...result, id: data?.eventUid });
                      //         eventOpenModal();
                      //         console.log(result);
                      //       }}
                      //     >
                      //       <a style={cursorPointerStyle}>사용하기</a>
                      //     </div>
                      //   ) : (
                      //     <DivWrapper className="btn-use">
                      //       <a style={cursorPointerStyle}>사용완료</a>
                      //     </DivWrapper>
                      //   )}
                      // </li>
                    );
                  })}
            </ul>
          </div>
        </div>
      </div>

      <ModalPortal>
        <CouponQrModal closeModal={closeModal} sn={sn} mode={mode} qrLists={qrLists} bootstrap={props.bootstrap} />
      </ModalPortal>

      <HistoryPortal>
        <HistoryModal closeModal={historyCloseModal} type={couponType} item={selectedBenefit} />
      </HistoryPortal>

      <EventModal>
        <EventQrModal closeModal={eventCloseModal} sn={''} eventQr={eventQr} bootstrap={props.bootstrap} />
      </EventModal>

      <InfoModalPortal>
        <InfoModal text={infoMessage} closeHandler={infoCloseModal} />
      </InfoModalPortal>
    </DivContainer>
  );
}

export default BlackCard;
