import { FC, useEffect, useState } from 'react';
import QRCode from 'react-qr-code';
import img_close_btn from '@assets/images/ico_pop-close.png';
import { cursorPointerStyle } from '@utils/stlyes';
import { getQrHolder } from '@api/app';
import styled from '@emotion/styled';

interface Props {
  closeModal: () => void;
}

const DivWrapper = styled.div`
  overflow-y: auto !important;
  .qr {
    display: flex !important;
    justify-content: center !important;
    align-content: center !important;
  }
`;

const VerifyHolderModal: FC<Props> = ({ closeModal }) => {
  const [qr, setQr] = useState('');

  const bootstrap = async () => {
    const result = await getQrHolder();
    setQr(result.qrCode);
  };

  useEffect(() => {
    bootstrap();
  }, []);

  return (
    <DivWrapper className="popup-wrap" style={{ display: 'block' }}>
      <div className="dim" />

      <div className="pop-type01 pop-qr-benefit">
        <a className="pop-close" onClick={closeModal} style={cursorPointerStyle}>
          <img src={img_close_btn} alt="" />
        </a>
        <strong className="pop-tit black text-l">홀더 인증하기</strong>
        <p className="desc color-orange">QR코드를 스캔하여 홀더 인증을 해주세요.</p>
        <div className="qr">
          <QRCode value={qr} size={200} />
        </div>
      </div>
    </DivWrapper>
  );
};

export default VerifyHolderModal;
