import { atom } from 'recoil';
import { BenefitLists } from '@api/app';

export const pathnameInfoAtom = atom<{ text: string; link: string } | null>({
  key: 'pathnameInfo',
  default: null,
});
export const currentLangAtom = atom({
  key: 'currentLanguage',
  default: 'KOR',
});

export const accountInfoAtom = atom({
  key: 'accountInfo',
  default: {
    address: '',
    name: '',
    nonceVerified: '',
    profile: '',
    uid: '',
    walletidx: -1,
  },
});

export const isLoginAtom = atom({
  key: 'isLoginAtom',
  default: false,
});

export const isMintLoadingAtom = atom({
  key: 'isMintLoading',
  default: false,
});

export const finishAtom = atom({
  key: 'finishAtom',
  default: 0,
});

export const modeTabAtom = atom<'offline' | 'online' | 'event'>({
  key: 'modeTabAtom',
  default: 'offline',
});

export const benefitListsAtom = atom<BenefitLists[]>({
  key: 'benefitListsAtom',
});

export const loginWalletAtom = atom<'kaikas' | 'klip' | ''>({
  key: 'loginWallet',
  default: '',
});
